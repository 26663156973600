/* Añadir estos estilos a tu archivo CSS existente */

.chatItem {
    display: flex;
    padding: 17px 16px;
    gap: 12px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.chatItem:hover {
    background-color: #131518;
}

.profilePhoto {
    width: 40px;
    height: 40px;
    object-fit: cover;
}

.emailProfilePhoto {
    border-radius: 20%;
}

.messageProfilePhoto {
    border-radius: 50%;
}

.chatInfo {
    flex: 1;
    min-width: 0;
    /* Permite que el texto se trunce correctamente */
    line-height: 19px;
}

.chatHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;
}

.userInfo {
    display: flex;
    gap: 4px;
    align-items: center;
    color: #e7e9ea;
}

.name {
    font-weight: 700;
    color: #e7e9ea;
    white-space: nowrap;
}

.username,
.time,
.dot {
    color: #71767b;
    font-size: 15px;
}

.lastMessage {
    color: #71767b;
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.unreadContent {
    color: #e7e9ea;
    font-weight: 600;
}

.unread {
    background-color: #16181c;
}

.unread .lastMessage {
    color: #e7e9ea;
    font-weight: 600;
}

.badge {
    width: 16px;
    margin-left: 6px;
}