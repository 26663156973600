.modalContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.zoomRange:hover {
    cursor: pointer;
}

.uploader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 600px;
    max-height: calc(100vh - 40px); /* 20px de margen arriba y abajo */
    background-color: black;
    border: none;
    outline: none;
    box-shadow: 24px 24px 48px rgba(0, 0, 0, 0.12);
    padding-top: 11px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    z-index: 1000;

}

.modalHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: transparent;
    width: 100%;
    padding-inline: 18px;
    padding-bottom: 10px;
    min-height: 32px;
}

.modalHeader > div {
    display: flex;
    flex-direction: row;
    gap: 30px;
    font-weight: 700;
    font-size: 20px;
    align-items: center;
    margin-top: -2px;
}

.modalHeader > button {
    width: 73.5px;
    height: 32px;
    background-color: #eff3f4;
    color: black;
    font-weight: 500;
    font-size: 15px;
    border-radius: 30px;
}

.modalHeader > button:hover {
    background-color: #d8dcdd;
}

.modalHeader img {
    height: 13px;
}

.cropperContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.controls {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;

}

/* Media queries para dispositivos móviles */
@media screen and (max-width: 700px) {
    .uploader {
        max-width: 100%;
        height: 100vh;
        max-height: 100vh;
        margin: 0;
        border-radius: 0;
        position: fixed;
        top: 0;
        left: 0;
        transform: none;
    }

    .modalHeader {
        padding-inline: 16px;
    }

    .modalHeader > div {
        gap: 20px;
        font-size: 18px;
    }
}

/* Para pantallas muy bajas */
@media screen and (max-height: 600px) {
    .modalHeader {
        padding-bottom: 5px;
    }

    .controls {
        height: 40px;
        padding: 5px 0;
    }
}

/* Para asegurar que el input range sea usable en móviles */
.zoomRange {
    -webkit-appearance: none;
    width: 80%;
    max-width: 300px;
    height: 4px;
    border-radius: 2px;
    background: #6de6b1;
    outline: none;
}

.zoomRange::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background: var(--corporate);
    cursor: pointer;
}

.zoomRange::-moz-range-thumb {
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background: #eff3f4;
    cursor: pointer;
    border: none;
}

.goBack {
    padding: 10px;
    border-radius: 30px;
    margin-left: -8px;
}

.goBack:hover {
    background-color: #141414;
}