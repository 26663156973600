/* styles.module.css */
.socialContainer {
    width: 100%;
    display: flex;
    gap: 30px;
    justify-content: center;
    background-color: black;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.feedContainer {
    margin-left:-26px; /* Para que quede a la misma altura el cotenedor con el borde que en Twitter / X */
    width: 600px;
    border-top: none;
    display: flex;
    justify-content: center;
    background-color: black;
}

.socialRightPart {
    position: sticky;
    position: -webkit-sticky; /* for Safari */
    top: 0; /* Ajusta la distancia desde la parte superior cuando se fija */
    align-self: flex-start; /* Alinea al inicio del contenedor */
}


@media (max-width: 1279px) {
    .feedContainer {
        margin-left: 0px; /* Elimina el margen izquierdo para evitar el solapamiento con el menú */

        width: 600px; /* Reduce el ancho del feed si es necesario */
    }
}

@media(max-width : 1100px){
    .socialContainer {
        gap: 20px;
    }
}

@media (max-width: 1030px) {
    .socialRightPart {
        display: none; /* Oculta la parte derecha en pantallas pequeñas */
    }
}

@media (max-width: 692px) {
    .feedContainer {
        width: 100%; /* Ocupa el 100% del espacio disponible */
        margin-left: 0; /* Asegura que el feed esté alineado con el borde izquierdo */
    }
}