/* WorldOrder.module.css */
.container {
  display: flex;
}


.sectionsContent {

  
  margin-left: 271px; /* 271px */
  flex-grow: 1; /* Ocupa todo el espacio restante a la derecha */
  height: 100%;
  overflow-y: auto; /* Permitir desplazamiento si el contenido es mayor que la altura disponible */
  background-color: white; /* Añadir un color de fondo si es necesario */
}

@media (max-width: 1279px){
  .sectionsContent {
    margin-left: 91px; /* 91px */
  }
}

/* Poner a 499px */
@media (max-width: 499px) {
  .sectionsContent {
    margin-left: 0px;
  }
}