.rankingContainer {
    display: flex;
    flex-direction: row;
    padding-inline: 20px;
    padding-block: 10px;
    justify-content: space-between;
    background-color: var(--tertiary-background);
}

.rankingContainer:hover {
    background-color: var(--hover);
    cursor: pointer;
}

.rankingHeader {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.rankingHeader p {
    font-weight: 600;
}

.cofounderDetails div {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
}

.mainContent {
    width: 100%;
}

/* styles.module.css */
.socialContainer {
    width: 100%;
    display: flex;
    gap: 30px;
    justify-content: center;
    background-color: black;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.feedContainer {
    margin-left: -26px;
    /* Para que quede a la misma altura el cotenedor con el borde que en Twitter / X */
    width: 600px;
    display: flex;
    flex-direction: column;
    background-color: black;
}

.socialRightPart {
    position: sticky;
    position: -webkit-sticky;
    /* for Safari */
    top: 0;
    /* Ajusta la distancia desde la parte superior cuando se fija */
    align-self: flex-start;
    /* Alinea al inicio del contenedor */
    padding-top: 7px;
}

.searchBarContainer {
    padding-inline: 20px;
    padding-top: 5px;
}


.searchBar {
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: 45px;

    background-color: black;
    border-radius: 30px;
    padding-block: 8px;
    padding-inline: 20px;

    border: 1px solid var(--border);
}

.searchBar input {
    background-color: black;
    outline: none;
    /* Elimina el contorno blanco por defecto */

}

.searchBar input::placeholder {
    color: #71767b;

}

.searchIcon {
    font-size: 22px;
    color: #71767b;
}

.tabContainer {
    width: 100.1%;
    padding-top: 5px;
    display: flex;
    justify-content: center;
}

.tabImage {
    width: 100%;
    object-fit: cover;
}

.card {
    display: flex;
    flex-direction: column;

    padding-block: 7px;
    padding-bottom: 15px;

}

.trendingItem {
    display: flex;
    flex-direction: column;
    padding-inline: 15px;
    padding-block: 13px;
}

.trendingItem:hover {
    background-color: #0f0f0f;
    cursor: pointer;
}

.trendingItem .name {
    font-weight: bold;
}

.trendingItem .trendCategoryAndRank {
    font-size: 14px;
    color: var(--secondary);
}

.noResults {
    font-weight: 500;

}

.noResultsContainer {
    display: flex;
    justify-content: center;
    padding-block: 20px;
}

.cofoundersList {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.cofounderItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-content: space-between;
    cursor: pointer;
    padding-inline: 15px;
    padding-block: 10px;
}

.cofounderItem:hover {
    background-color: var(--elegant-hover);
}

.cofounderInfo {
    display: flex;
    align-items: center;
    gap: 10px;
}

.cofounderAvatar {
    width: 45px;
    height: 45px;
    border-radius: 50%;
}

.cofounderDetails {
    display: flex;
    flex-direction: column;
}

.cofounderDetails p {
    line-height: 20px;

}

.name {
    font-weight: 600;
}

.username {
    color: #808080;
}

.description {
    margin-top: 4px;
    /* Añadimos un pequeño espacio arriba de la descripción */
    color: #E7E9EA;
    /* Color típico de Twitter para el texto */
    line-height: 1.3;
    /* Mejoramos la legibilidad */
}

.aboutContainer {
    padding-left: 55px;
}

@media (max-width: 1279px) {
    .feedContainer {
        margin-left: 0px;
        /* Elimina el margen izquierdo para evitar el solapamiento con el menú */

        width: 600px;
        /* Reduce el ancho del feed si es necesario */
    }
}

@media(max-width : 1100px) {
    .socialContainer {
        gap: 20px;
    }
}

@media (max-width: 1000px) {
    .socialRightPart {
        display: none;
        /* Oculta la parte derecha en pantallas pequeñas */
    }
}

@media (max-width: 692px) {
    .feedContainer {
        width: 100%;
        /* Ocupa el 100% del espacio disponible */
        margin-left: 0;
        /* Asegura que el feed esté alineado con el borde izquierdo */
    }
}