html,
body {
    overflow-x: hidden;
}

.authContainer {
    display: flex;
    flex-direction: column;
    width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;
}

.authHorizontalContent {
    display: flex;
    flex: 1;
    width: 100%;
    height: auto; /* Dejar que el contenido determine la altura */
}

.leftSection {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rightSection {
    flex: 1;
    display: flex;
    align-items: center;
    padding-bottom: 2%;
}

.leftContent {
    transform: translate(6%, -6%);
}

.rightContent {
    margin-top: 24%;
    margin-left: 11%;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.rightContent h1 {
    font-size: 66px;
    letter-spacing: 1.5px;
    font-family: 'Inter';
    margin-top: 8%;
    line-height: 1.3;
}

.rightContent h2 {
    font-size: 32px;
    letter-spacing: 1.5px;
    font-family: 'Inter';
    margin-top: 8%;
    margin-bottom: 2.5%;
}

.googleLogin {
    margin-top: 2.5%;
}

.separator {
    display: flex;
    align-items: center;
    margin-top: 2%;
}

.separator hr {
    width: 29.2%;
}

.separator p {
    font-family: 'Inter';
    margin-left: 8px;
    margin-right: 8px;
    font-size: 15px;
}

.createAccountBtn {
    margin-top: 1.5%;
    width: 65%;
    height: 4.7%;
    font-size: 14px;
    border-radius: 200px;
    letter-spacing: 0.5px;
    background-color: var(--corporate);
    color: white;
    border: none;
}

.terms {
    color: #71767b;
    font-size: 11px;
    margin-top: 1%;
    line-height: 12.5px;
}

.terms a {
    color: var(--corporate);
    text-decoration: none;
}

.rightContent h3 {
    font-size: 17px;
    margin-top: 12%;
}

.loginBtn {
    margin-top: 3.5%;
    color: var(--corporate);
    height: 4.7%;
    width: 64%;
    letter-spacing: 0.5px;
    border-width: 1px;
    border-color: #71767b;
    border-radius: 29px;
}

.footer {
    position: relative;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 20px;
}

.footer a {
    color: #71767b;
    font-size: 13px;
    margin-left: 1.5%;
}

.svg {
    transform: scale(0.65);
}

/* Móviles 768px */
@media (max-width: 525px) {

    .authContainer {
        flex-direction: column;
        height: auto;
    }

    .authHorizontalContent {
        flex-direction: column;
    }

    .leftSection {
        width: 100%;
        height: auto;
    }

    .rightSection {
        width: 100%;
        height: auto;
        padding: 20px;
        margin-top: -3%;
        padding-bottom: 5%;
    }

    .leftContent {
        transform: translate(0, 0);
    }

    .rightContent {
        transform: translate(0, 0);
        height: auto;
        padding: 10px;
        margin-top: 0;
        margin-left: 0;
    }

    .rightContent h1 {
        font-size: 2.6em;
        margin-top: 0%;
    }

    .rightContent h2 {
        font-size: 1.5em;
        margin-top: 10.6%;
    }

    .createAccountBtn,
    .loginBtn {
        width: 307px;
        height: 41px;
        font-size: 16px;
    }

    .separator hr {
        width: 42%;
    }

    .separator p {
        font-size: 14px;
    }

    .terms {
        font-size: 12px;
        margin-top: 2%;
    }

    .rightContent h3 {
        font-size: 1.1em;
        margin-top: 15.8%;
    }

    .footer {
        position: relative;
        bottom: 0;
        padding: 20px;
    }

    .footer a {
        font-size: 14px;
    }

    .svg {
        transform: scale(0.37) translate(-15%, -1%);
        height: auto;
        width: 25.5%;
    }
}

/* Tablets: 769px - 1024px */
@media (min-width: 526px) and (max-width: 1024px) {

    .authContainer {
        flex-direction: column;
        height: auto;
    }

    .authHorizontalContent {
        flex-direction: column;
    }

    .leftSection {
        width: 100%;
        height: auto;
    }

    .rightSection {
        width: 100%;
        height: auto;
        padding: 20px;
    }

    .rightSection {
        padding-bottom: 5%;
    }

    .leftContent {
        transform: translate(0, 0);
    }

    .rightContent {
        transform: translate(0, 0);
        height: auto;
        padding: 10px;
        margin-top: 0;
        margin-left: 0;
    }

    .rightContent h1 {
        font-size: 4em;
        margin-top: 0%;
    }

    .rightContent h2 {
        font-size: 2em;
        margin-top: 8%;
    }

    .createAccountBtn,
    .loginBtn {
        width: 307px;
        height: 41px;
        font-size: 16px;
    }

    .separator hr {
        width: 40%;
    }

    .separator p {
        font-size: 14px;
    }

    .terms {
        font-size: 12px;
        margin-top: 5%;
    }

    .rightContent h3 {
        font-size: 20px;
        margin-top: 10%;
    }

    .footer {
        position: relative;
        bottom: 0;
        padding: 20px;
    }

    .footer a {
        font-size: 14px;
    }

    .svg {
        transform: scale(0.37) translate(-15%, -1%);
        height: auto;
        width: 24%;
    }
}
