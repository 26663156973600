.loadingContainer {
    height: 99vh;
    display: flex;
    justify-content: center;
    align-items: center;

}

.loadingContainer img {
    height: 80px;
    margin-bottom: 60px;
}

.mainContent {

    width: 100%;

    border-top: none;

    min-height: 100vh;

    height: auto;
}

.headerTitle {
    font-size: 1.28rem;
    font-weight: bold;
    opacity: 0.9;
    user-select: none;
    margin-left: 1.1rem;
    margin-top: 5px;
}

.arrowIcon {
    cursor: pointer;
    margin-top: 1px;
}

.rightSide {
    position: fixed;
    right: 0;
    margin-right: 1.5%;
    width: 20%;
}


.profileTab {
    padding-top: 15px;
    padding-inline: 25px;
    color: var(--secondary);

    display: flex;
    flex-direction: column;
    gap: 20px;
    /* Ajusta el valor según la cantidad de separación deseada */
}

.nationalitySection {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.nationalitiesList {
    display: flex;
    flex-direction: row;
    color: white;
    gap: 1rem;
    font-weight: bold;

}

.nationalitiesList img {
    height: 40px;
}

.nationalityInfo {
    display: flex;
    flex-direction: column;
}

.skillsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}


.skillForProfile {
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
    padding: 10px;
    /* Incrementa el padding según sea necesario */
    align-items: center;
}

.skillDetails {
    display: flex;
    flex-direction: column;
}


.skills:hover {
    cursor: pointer;
    background-color: #171717;
    color: white;
}

.skillForOwnProfile {
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
    padding: 10px;
    /* Incrementa el padding según sea necesario */
    align-items: center;
}

.buttonsSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.buttonsSection .icon {
    width: 1.25rem;
    height: 1.25rem;
    color: #e0e0e0;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    width: 100%;
    padding-block: 10px;
    border: 1px solid #4b4b4b;
    border-radius: 0.375rem;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.2s ease;
    color: #e0e0e0;
  }

  .button:hover {
    background-color: #151616;
    font-weight: bold;
}

  .territorialOrganizationButton {
    color: #e0e0e0;
    border: 1px solid #4b4b4b;
    padding-block: 10px;
    padding-inline: 30px;
    width: 100%;
    border-radius: 100px;

    margin-bottom: 30px;
}

.territorialOrganizationButton:hover {
    background-color: #151616;
    font-weight: bold;
}


.profileElement {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.profileElementContent {

    width: 410px;
    display: flex;
    justify-content: center;


}

.experienceBarContainer {
    width: 413px;
    background-color: #e0e0e0;
    /* Color de fondo de la barra de experiencia */
    border-radius: 10px;
    /* Bordes redondeados para la barra de experiencia */
    height: 10px;
    /* Altura de la barra de experiencia */
    margin-top: 8px;
    /* Espacio entre el texto y la barra de experiencia */
}

.experienceBar {
    height: 100%;
    background-color: var(--corporate);
    /* Color de la barra de experiencia */
    border-radius: 10px;
    /* Bordes redondeados para la barra de experiencia */
}

.editFollowUnfollowButton {
    border-radius: 20px;
    border: 1px solid #536471;
    background-color: black;
    padding-inline: 15px;
    padding-block: 6px;
    margin-top: 72px;
    transition: background-color 0.3s ease;
}

.editFollowUnfollowButton:hover {
    background-color: #151616;
}

.profileHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    top: -73px;
    /* Mueve la imagen de perfil hacia arriba la mitad de su altura */
    padding-right: 14px;
    padding-left: 18px;
}

.rightContainer {
    display: flex;
    align-items: center;
    margin-left: auto;
    /* Empuja este div al extremo derecho */
    padding-right: 15px;
    /* Opcional: para agregar espacio en el extremo derecho */
}

.rightContainer p {
    font-weight: bold;
    margin-left: 2px;
}

.hotIcon {
    margin-right: 5px;
    /* Espacio entre el icono y el texto */
}

.profileImage {
    width: 140px;
    /* Ajusta el tamaño según tus necesidades */
    height: 140px;
    /* Ajusta el tamaño según tus necesidades */
    border-radius: 8%;
    /* Hace que la imagen sea circular */
    object-fit: cover;
    /* Ajusta la imagen dentro del círculo */
    border: 4px solid transparent;
    /* Opcional: borde blanco alrededor de la imagen */
}

.profileInfo {
    padding-left: 1rem;
    margin-top: -73px;
    /* Ajusta la sección de información del perfil para que suba junto con la imagen de perfil */
}

.backgroundImage {
    width: 100%;
    height: 200px;
    object-fit: cover;
    margin-top: 52px;
}

.fixedHeader {
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 1000;
    top: 0;
    width: 598px;
    height: 53px;
    background-color: rgba(0, 0, 0, 0.6);
    /* Negro con 40% de opacidad */
    backdrop-filter: blur(8px);
    /* Aplica un desenfoque de 8px al fondo */
    padding-left: 15px;
    padding-block: 13px;
}


.avatar {
    transform: translateY(-50%);
    width: 10rem;
    height: 10rem;
    border: 4px solid white;
}

.userDetails {
    display: flex;
    flex-direction: column;
    margin-top: 13px;
    padding-right: 15px;
}

.userInfo {
    display: flex;
    align-items: center;
}

.name {
    font-size: 1.28rem;
    font-weight: bold;
    padding: 0;
}

.username {
    font-size: 1rem;
    font-weight: normal;
    color: var(--secondary);

    line-height: 15px;

    padding: 0;

}

.userStats {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    color: var(--secondary);
}

.userStat {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.userStatsIcons {
    font-size: 20px;
    color: var(--secondary);
}


.grayText {
    color: var(--secondary);
}

.tabContainer {
    width: 100%;
    padding-top: 1.2rem;
}

.stateBadge {
    width: 16px;
    margin-left: 6px;
}


/* Para el Tab de Statistics */

.statisticsQuarterAndYear {
    padding-top: 15px;
    padding-inline: 25px;
    padding-bottom: 10px;
}

.popsDistributions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.statisticsContainer {
    padding-top: 10px;
    padding-bottom: 20px;

    overflow-y: auto;
    /* Añade scroll si el contenido desborda verticalmente */
    overflow-x: hidden;
    /* Oculta cualquier desbordamiento horizontal */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* Dos elementos por fila */
    justify-items: center;
    /* Centra los elementos horizontalmente */
    align-items: stretch;
    /* Ensures equal heights for divs in a row */

    gap: 10px;

    width: 100%;
}

.pieChartWrapper {
    width: 100%;
    display: flex;
}

.pieChartWrapper:nth-child(odd) {
    justify-content: flex-end;
}

.pieChartWrapper:nth-child(even) {
    justify-content: flex-start;
}

.quarterDropdown {
    padding-left: 15px;
}

.elementTitle {
    display: flex;
    flex-direction: column;
    gap: 6px;
    color: white;
    font-weight: bold;
    font-size: 18px;
}

/* Fin de para el Tab de Statistics */


@media (max-width : 690px) {
    .statisticsContainer {
        grid-template-columns: repeat(1, 1fr);
        /* Dos elementos por fila */
    }

    .pieChartWrapper:nth-child(odd) {
        justify-content: center;
    }

    .pieChartWrapper:nth-child(even) {
        justify-content: center;
    }
}

@media (max-width : 499px) {
    .statisticsContainer {
        padding-bottom: 70px;
    }
}