/* tabs.module.css */
.tabsContainer {
  width: 100%;
}

/* Nuevo contenedor wrapper para mantener el borde inferior completo */
.tabsWrapper {
  border-bottom: 1px solid var(--border);
  width: 100%;
  position: relative;
}

.tabs {
  display: flex;
  overflow-x: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  position: relative;
  white-space: nowrap;
}

/* Ocultar scrollbar para Chrome, Safari y Opera */
.tabs::-webkit-scrollbar {
  display: none;
}

.tab {
  cursor: pointer;
  color: var(--secondary);
  transition: color 0.3s ease;
  min-width: 120px; /* Ancho mínimo para cada tab */
  flex-shrink: 0; /* Evita que los tabs se compriman */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 16px 0;
  position: relative;
  height: 51px;
  font-weight: 500;
}

.tab:hover {
  background-color: var(--elegant-hover);
}

.tab::after {
  content: "";
  display: block;
  height: 4px;
  width: 50px;
  background-color: transparent;
  border-radius: 100px;
  margin-top: 13px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.tab.active {
  color: #ffffff;
  font-weight: bold;
}

.tab.active::after {
  background-color: var(--corporate);
}

.tabContent {
  padding-top: 0px;
}