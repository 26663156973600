/* Contenedor del dropdown, se coloca encima de la página */
.dropdownContainer {
    position: fixed;
    right: 20px;
    bottom: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 1000; /* Asegura que esté encima de otros elementos */
  }
  
  /* El menú desplegable */
  .dropdownMenu {
    background-color: black;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.2); 
    width: 300px;
    max-width: 90%;
    position: relative;
  }
  
  /* Lista del menú */
  .menuList {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .menuList li {
    padding: 10px;
    cursor: pointer;
  }
  
  .menuList li:hover {
    background-color: #f0f0f0; /* Efecto hover en los items */
  }

  @media (max-width : 768px){
    .dropdownContainer {
      right: 5px;
      bottom: 145px;
    }
  }