body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


}

:root {
  --primary: #E7E9EA;
  --secondary: #71767B;
  --corporate: #099F5D;
  --red: #ff3b30;
  --strong-red: #ff1f1f;
  --orange: #eab308;
  --border: #2c2f32;
  --hover: #171717;
  --elegant-hover: #16181c;
  --secondary-background: #16181c;
  --tertiary-background: #0f0f0f;
  --silver: #536471;
}

button:hover {
  cursor: pointer;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/*.hideScrollBar::-webkit-scrollbar{
  display:none
}*/

.scrollBarStyle::-webkit-scrollbar {
  width: 10px; /* Ajusta el ancho según tus preferencias */
}

.scrollBarStyle::-webkit-scrollbar-thumb {
  background-color: #888; /* Color del thumb (la barra deslizante) */
  border-radius: 5px; /* Bordes redondeados del thumb */
}

/* Estilos para Firefox */
.scrollBarStyle {
  scrollbar-width: thin;
  scrollbar-color: #888 transparent; /* Color del thumb y el track (transparente para Firefox) */
}

@tailwind base;
@tailwind components;
@tailwind utilities;
