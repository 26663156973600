.nameAndUsername > p:first-child{
    font-weight: 500;
}

.nameAndUsername > p:last-child{
    color: #71767b;
}

.positionAndProfile {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.positionAndProfile > p {
    font-weight: bold;
    font-size: 18px;
}

.profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.profile img {
    height: 35px;
    border-radius: 6px;
}

.nameAndUsername {
    display: flex;
    flex-direction: column;
    line-height: 20px;
}

/* Contenedor del dropdown, se coloca encima de la página */
.dropdownContainer {
    position: fixed;
    left: 634px;
    top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 1000;
    /* Asegura que esté encima de otros elementos */
}

/* El menú desplegable */
.dropdownMenu {
    background-color: black;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(40, 47, 50, 0.8); 
    width: 300px;
    max-width: 90%;
    position: relative;
    overflow: hidden;
}

/* Lista del menú */
.menuList {
    list-style: none;
    padding: 0;
    margin: 0;
}

/* Para los elementos hijo directos de menuList */
.menuList > * {
    padding-inline: 10px;
    padding-block: 8px;
    cursor: pointer;
    /* Añadimos transición suave para el hover */
    transition: background-color 0.2s ease;
}

/* Para el hover de cualquier elemento dentro de menuList */
.menuList > *:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.menuList > *:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.menuList > *:hover {
    background-color: var(--border);
}

@media(max-width : 1279px){
    .dropdownContainer {
        left: 455px;
    }
}

@media(max-width : 1125px){
    .dropdownContainer {
        position: fixed;
        left: auto;
        right: 0px;
    }
}
