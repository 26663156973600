/* styles.module.css */

.mediaContent {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 0.5rem;
    align-items: center;

}

.mediaContent > div {
    flex: 1 1 50%; /* Cada elemento ocupará el 50% del ancho */
}

.container {
    display: flex;
    flex-direction: column;
    width: 100vw;


}

.emptyDiv {
    height: 52px;
}

.header {
    position: fixed;
    top: 0;
    width: 600px;

    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: rgba(0, 0, 0, 0.6);
    /* Negro con 40% de opacidad */
    backdrop-filter: blur(8px);

    color: white;

    gap: 15px;

    border-bottom: 1px solid #2d3033;
    border-inline: 1px solid var(--border);

    z-index: 1000;
}

.header.visible {
    transform: translateY(0);
    /* Mantiene el header en su posición original */
    transition: transform 0.5s ease-in-out;
}

.header img {
    width: 27px;

    margin-top: 15px;
    display: none;
}

.header .buttonContainer button {
    flex-grow: 1;
    padding: 11px 15px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    text-align: center;
}

.header .buttonContainer button:hover {
    background-color: #171818;
}

.buttonContainer {
    display: flex;
    width: 100%;
}


.buttonContainer button p {
    height: 29px;
    color: #71767b;

    font-size: 15px;
    padding-top: 3px;

    font-weight: 500;

}



.buttonContainer button.active p {
    position: relative;
    /* Necesario para posicionar el ::after */
    margin: 0;
    display: inline-block;
    color: white;

    font-weight: bold;
}

.active p::after {
    content: "";
    position: absolute;
    bottom: -10px;
    /* Ajusta este valor para posicionar la barra */
    left: 50%;
    transform: translateX(-50%);
    /* Centra la barra */
    width: 110%;
    /* Ajusta el ancho de la barra */
    height: 4px;
    /* Altura de la barra */
    background-color: var(--corporate);
    border-radius: 2px;
    /* Bordes suavizados */
}



.formContainer {
    display: flex;
    gap: 1.25rem;
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 13px;

    border-inline: 1px solid var(--border);



    border-bottom: 1px solid var(--border);
}

.formContent {
    width: 100%;
}

.inputField {
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 1.25rem;
    width: 100%;
    resize: none;
    overflow: hidden;
    line-height: 1.5;
    padding: 0;
    min-height: 38px;
}

.imageVideoInputField {
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 1.1rem;
    width: 100%;
    resize: none;
    overflow: hidden;
    line-height: 1;
    padding: 0;
    min-height: 38px;
}

.errorText {
    color: #f87171;
}

.formActions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
    gap: 1rem;
    /* Ajusta el espacio entre elementos */
}

.actionItems {
    display: flex;
    gap: 1.25rem;
    align-items: center;
}

.iconText {
    color: var(--corporate);
}

.iconText:hover {
    cursor: pointer;
}


.socialPostButton {
    border-radius: 100px;
    padding: 5px 20px;
    background-color: var(--corporate);
    color: white;
    text-align: center;
    cursor: pointer;
}

.socialPostButton:hover {
    background-color: #087f49;
}

.mobileSocialPostButton {
    display: none;
}

.selectedImage {
    width: 60rem;
    border-radius: 0.75rem;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
}

.circularProgressContainer {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.contentWrapper {
    flex-grow: 1;
    height: 100vh;
    /* Altura total menos la altura del header */
}

.imageVideoInputContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px; /* Espacio entre el input y el botón */
}

.imageVideoInputField {
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 1.1rem;
    width: 100%;
    resize: none;
    overflow: hidden;
    line-height: 1;
    padding: 0;
    min-height: 38px;
    flex: 1; /* Permite que el campo de entrada ocupe el espacio disponible */
}


.imageVideoInputButton {
    border: 1px solid #71767b;
    padding: 5px 20px;
    color: #71767b;
    border-radius: 30px;
    font-weight: 500;
    font-size: 15px;
    background-color: transparent; /* Fondo transparente */
    cursor: pointer;
    transition: background-color 0.3s ease;
    flex-shrink: 0; /* Evita que el botón se encoja */
    align-self: flex-start; /* Alinea el botón con la parte superior del contenedor */
}



.imageVideoInputButton:hover {
    background-color: #111111;
}

.imageVideoContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.mediaPreview {
    margin-bottom: 1rem;
    max-width: 100%;
}

.videoWrapper {
    position: relative;
    /* 16:9 Aspect Ratio */
    max-width: 100%;
    overflow: hidden;

    
}

.responsiveIframe {
    position: relative;
    top: 0;
    left: 0;
    max-width: 100%;

}

.responsiveImage {
    max-width: 100%;
    height: auto;
    border-radius: 15px;
}

.mediaWrapper {
    position: relative;
    display: inline-block;
}

.removeMediaButton {
    position: absolute;
    top: 5px;
    left: 5px;
    background-color: rgba(43, 44, 45);
    border: none;
    border-radius: 50%;
    padding: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.closeIcon {
    width: 12px;
}

.removeMediaButton:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

@media (max-width : 420px) {

    .socialPostButton {
        display: none;
    }

    .mobileSocialPostButton {
        display: block;
    }
}

@media (max-width : 692px) {
    .header {
        width: 87%;
        border-inline: none;
    }

    .formContainer {
        border-inline: none;
    }
}

@media (max-width : 499px) {

    .formContainer {
        display: none;
    }

    .header {
        width: 100%;
    }

    .header img {
        display: block;
    }

    .emptyDiv {
        height: 109px;
    }

    .header.hidden {
        transform: translateY(-100%);
        /* Mueve el header hacia arriba fuera de la vista */
        transition: transform 0.5s ease-in-out;
    }
}