.morePageContainer {
    background-color: #000000;
    border-left: 1px solid var(--border);
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.menuGrid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 15px;
    width: 100%;
    max-width: 1200px;
}

.menuItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 20px;
    padding: 15px;
    border: 1px solid var(--border);
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.2s;
}

.menuItem:hover {
    background-color: var(--hover);
}

@media (max-width: 1200px) {
    .menuGrid {
        grid-template-columns: repeat(5, 1fr);
    }
}

@media (max-width: 992px) {
    .menuGrid {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 768px) {
    .menuGrid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 576px) {
    .menuGrid {
        display: flex;
        flex-wrap: wrap; /* Permite que los elementos se envuelvan en varias filas */
        justify-content: flex-start;
        align-items: stretch; /* Asegura que los elementos ocupen toda la altura de la fila */
        overflow-y: auto;
        height: 100vh;
        padding: 10px;
        gap: 0; /* Eliminamos el gap para controlar el espaciado manualmente */

        padding-bottom: 50px;
    }

    .menuItem {
        width: calc(50% - 5px); /* Mitad del contenedor menos un pequeño espacio */
        padding: 8px; /* Reducimos el padding para minimizar el espacio vertical */
        font-size: 16px; /* Opcional: Reducir el tamaño de la fuente */
        box-sizing: border-box; /* Asegura que el padding no afecte el ancho */
        margin-bottom: 10px; /* Espacio vertical entre filas */
    }

    .menuItem:nth-child(odd) {
        margin-right: 10px; /* Espacio horizontal entre elementos */
    }

    .morePageContainer {
        justify-content: flex-start;
        align-items: flex-start;
    }
}