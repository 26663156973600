.pageContainer {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #1b1b1b;
    overflow: hidden;
    /* Cambiado de overflow-y: auto */
}

.header {
    background: black;
    position: fixed;
    top: 0;
    right: 15px;
    left: 272px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 11px 30px;
    gap: 40px;
    color: white;
    z-index: 1000;
    justify-content: space-between;
    height: 75px;
    box-sizing: border-box;
    flex-shrink: 0;
    /* Evita que el header se encoja */
}

.header img {
    height: 35px;
}

.searchBar {
    display: flex;
    flex-direction: row;
    gap: 10px;

    width: 30%;

    background-color: #202327;
    border-radius: 30px;
    padding-block: 7px;
    padding-inline: 20px;

}

.searchBar input {
    background-color: #202327;
    outline: none;

    width: 100%;
    /* Elimina el contorno blanco por defecto */

}

.searchBar input::placeholder {
    color: #71767b;

}

.searchIcon {
    font-size: 22px;
    color: #71767b;
}

.signIn {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
}

.signIn p:hover {
    text-decoration: underline;
    cursor: pointer;
}

.contentWrapper {
    flex-grow: 1;
    overflow-y: auto;
    height: calc(100vh - 75px);
    /* Altura total menos la altura del header */
}

.presentation {
    display: flex;
    flex-direction: column;
    padding-left: 60px;
    background-image: url('./AlibabaBackground.png');
    background-size: cover;
    background-position: center;
    height: 500px;
    gap: 25px;
    justify-content: center;
}

.presentation .description {

    width: 55%;
    font-size: 20px;

}

.presentation .title {
    font-weight: bold;
    font-size: 50px;

    width: 50%;
}

.currency {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.country {
    display: flex;
    flex-direction: row;
    gap: 10px;

    align-items: center;
}

.managementPanel {
    background-color: #FF6600;
    color: black;
    font-weight: bold;
    padding-block: 6px;
    padding-inline: 18px;
    border-radius: 30px;
    font-size: 15px;
    width: 200px;
    min-width: 175px;
    /* Ancho mínimo */

}

.productCategories {
    display: flex;
    flex-direction: row;
    background-color: #3a190b;
    padding-block: 40px;
    padding-inline: 50px;

    gap: 40px;
    min-height: 200px;

    justify-content: center;
    /* Centra los elementos horizontalmente */
}

.category {
    display: flex;
    flex-direction: column;
    background-color: #402013;
    border-radius: 15px;
    padding: 40px;

    overflow-y: auto;

    width: 350px;

    gap: 15px;


}

.category div {
    background-color: #4f3126;
    width: 65px;
    /* Ancho fijo que coincide con la altura */
    height: 65px;
    /* Altura fija que coincide con el ancho */
    border-radius: 50%;
    /* Esto crea un círculo perfecto */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    /* Eliminamos el padding ya que ahora centramos el icono */
}

.category .title {
    /* Estilos para el título si es necesario */
    font-weight: bold;
    font-size: 23px;
}

.category .description {
    /* Estilos para la descripción si es necesario */
    font-size: 17px;
}


.category:hover {
    cursor: pointer;
    background-color: #52220a;
}



.category div .icon {
    font-size: 35px;
    /* Ajusta el tamaño como prefieras */
    color: #b4adaa;
}

.category:hover .icon {
    color: #FF6600;
}

.testimonialsSection {
    background-color: #101010;
    padding-block: 40px;
    padding-inline: 10%;
}

.testimonial {
    background-color: #3a3a3a;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 20px;
    border-radius: 20px;

    gap: 30px;

}

.testimonialImage {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    object-fit: cover;
    background-color: #fff;
    /* Color de fondo blanco */
    border: 3px solid #ccc;
    /* Borde gris de 5px */
}


.testimonialText {
    font-size: 18px;
    line-height: 1.5;
}

.testimonialText cite {
    font-style: normal;
    font-size: 14px;
    display: block;
    margin-top: 10px;
}

.header.visible {
    transform: translateY(0);
    /* Mantiene el header en su posición original */
    transition: transform 0.5s ease-in-out;
}



@media(max-width : 1600px) {

    .productCategories {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-items: center;
    }

    .productCategories>div:nth-child(odd) {
        /* Para los elementos en las columnas impares (1, 3, 5, ...) */
        justify-self: end;
        /* Alinea a la derecha */
    }

    .productCategories>div:nth-child(even) {
        /* Para los elementos en las columnas pares (2, 4, 6, ...) */
        justify-self: start;
        /* Alinea a la izquierda */
    }

    .presentation .description {

        width: 70%;

    }

    .presentation .title {

        width: 70%;
    }

}

@media(max-width : 1400px) {

    .currency {
        display: none;
    }

    .country {
        display: none;
    }

}

@media (max-width : 1279px) {

    .header {
        left: 90px;
    }

}

@media(max-width : 1000px) {

    .managementPanel {
        display: none;
    }
}

@media (max-width: 910px) {
    .productCategories {
        grid-template-columns: 1fr;
        /* Una sola columna */
        justify-items: center;
        /* Centra todos los elementos en la columna */
    }

    .productCategories>div {
        justify-self: center !important;
        /* Asegura que todos los elementos estén centrados */
    }

    .presentation {
        height: 560px;

        padding-right: 60px;
    }

    .presentation .description {

        width: 100%;
        font-size: 17px;

    }

    .presentation .title {

        width: 100%;
        font-size: 42px;
    }

    .testimonial {
        flex-direction: column;
    }

}

@media (max-width: 768px) {

    .testimonial {
        margin-bottom: 50px;
    }

}

@media (max-width: 700px) {

    .searchBar {
        display: none;
    }


}

@media (max-width: 550px) {

    .presentation {
        padding-inline: 40px;
        background-position: bottom left;
    }

    .presentation .description {

        padding-right: 60px;
        font-size: 16px;

    }

    .presentation .title {

        font-size: 35px;
    }

    .signIn p {
        display: none;
    }

}

@media (max-width : 499px) {

    .header {
        right: 0px;
        left: 0px;
    }

    .header.hidden {
        transform: translateY(-100%);
        /* Mueve el header hacia arriba fuera de la vista */
        transition: transform 0.5s ease-in-out;
    }

}

@media (max-width: 410px) {

    .presentation {
        height: 600px;
    }

}