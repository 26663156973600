.mobileButton {
    display: none;

    position: fixed;
    bottom: 74px;
    right: 20px;
    background-color: var(--corporate);
    
    /* Sombra blanca más sutil y uniforme */
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.3); 

    border-radius: 30px;
    /* Opcional: puedes ajustar la opacidad o difuminado */
    border: none;
    transition: box-shadow 0.3s ease;

    padding: 17px;
}


.mobileButtonIcon {
    color: white;
    height: 21px;
}

.replyButtonIcon {
    color: white;
    height: 20px;
}

.postResponseContainer {
    display: none;

    position: fixed;
    height: 100vh;
    width: 100vh;
    z-index: 1000;

    display: flex;


    overflow: hidden;

    background-color: var(--corporate);
}

.icon {
    height: 13px;
}


@media(max-width : 499px) {
    .mobileButton {
        display: block;
    }

    .postResponseContainer {
        display: block;
    }
}
