.contentHeader {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  padding-bottom: 15px;
}

.gapText {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contentSection .accountEmail {
  color: var(--primary);
  font-weight: 500;
  padding-top: 10px;
  font-size: 16px;
}

.contentSection p {
  font-size: 14px;
  color: var(--secondary);
}

.adminBoardButton {
  color: white;
  padding: 7px 10px;
  border: 1px solid var(--border);
  border-radius: 30px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;
  width: 100%; /* Reduce el ancho */
  margin: 0px auto 0; /* Añade margen superior y centra horizontalmente */
  display: block; /* Necesario para que el auto funcione en márgenes horizontales */
}

.adminBoardButton:hover {
  background-color: var(--elegant-hover);
}

.logoutButton {
  background-color: var(--red);
  color: white;
  padding: 7px 20px;
  border: none;
  border-radius: 6px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;
  width: 100%; /* Reduce el ancho */
  margin: 20px auto 0; /* Añade margen superior y centra horizontalmente */
  display: block; /* Necesario para que el auto funcione en márgenes horizontales */
}

.logoutButton:hover {
  background-color: var(--strong-red);
}

.changePasswordButton {
  background-color: var(--corporate);
  color: white;
  padding: 7px 20px;
  border: none;
  border-radius: 6px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;
  width: 80%; /* Reduce el ancho */
  margin: 20px auto 0; /* Añade margen superior y centra horizontalmente */
  display: block; /* Necesario para que el auto funcione en márgenes horizontales */
}

.changePasswordButton:hover {
  opacity: 0.9;
}


.wrapper {
  background-color: #000;
  min-height: 100vh;
  width: 100%;
  padding-left: 98px;
}

.socialContainer {
  display: flex;
  min-height: 100vh;
  background-color: #000;
  color: #fff;
  max-width: 1100px;
  margin: 0 auto;
  position: relative;
}

.settingsMenu {
  width: 450px;
  padding: 0;
  flex-shrink: 0;
  position: sticky;
  top: 0;
  height: 100vh;
  border-inline: 1px solid var(--border);
}

.menuTitle {
  font-size: 20px;
  font-weight: bold;
  padding: 16px;
  margin: 0;
  color: var(--primary);
}

.menuList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menuItem {
  margin: 0; /* Asegúrate de que no haya margen en los elementos de la lista */
  padding: 0; /* Asegúrate de que no haya padding en los elementos de la lista */
}

.iconColor {
  color: var(--secondary);
}

.menuLink {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 16px;
  padding-block: 12px;
  color: #fff;
  text-decoration: none;
  transition: background-color 0.2s;
}

.menuLink:hover {
  background-color: var(--elegant-hover);
}

.menuIcon {
  margin-right: 12px;
  font-size: 18px;
}

.settingsContent {
  padding-top: 10px;
  max-width: 600px;
  flex-grow: 1;
  border-right: 1px solid var(--border);
}

.contentSection {
  padding-inline: 14px;
  padding-block: 12px;
}

.contentSection h2 {
  font-size: 22px;
  display: flex;
  align-items: center; /* Alinea el título y el botón verticalmente */
  gap: 10px; /* Espacio entre el botón y el título */
}

.goBack {
  padding: 10px;
  border-radius: 30px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.goBack:hover {
  background-color: #141414;
}

.goBack img {
  height: 13px;
}

.backButton {
  background: none;
  border: none;
  color: #fff;
  font-size: 16px;
  padding: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.backButton:hover {
  background-color: rgba(239, 243, 244, 0.1);
  border-radius: 100px;
}

@media (max-width: 1300px) {
  .socialContainer {
    max-width: 1000px;
  }
}

@media (max-width: 1279px) {
  .wrapper {
    padding-left: 0px;
  }
}

@media (max-width: 1092px) {
  .settingsMenu {
    width: 300px;
  }

  .settingsContent {
    width: 100%;
    max-width: none; /* Elimina cualquier max-width */
    flex-grow: 1; /* Asegura que ocupe todo el espacio */
    border-left: none; /* Elimina el borde izquierdo si es necesario */
  }
}

@media (max-width: 829px) {
  .socialContainer {
    max-width: none; /* Elimina cualquier max-width */
    width: 100%;
    flex-direction: column; /* Cambia la dirección a columna */
  }

  .settingsMenu {
    width: 100%;
    height: auto;
    position: static;
  }

  .settingsContent {
    width: 100%;
    max-width: none; /* Elimina cualquier max-width */
    flex-grow: 1; /* Asegura que ocupe todo el espacio */
    border-left: 1px solid var(--border);
  }
}