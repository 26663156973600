.mediaContent {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 0.5rem;
    align-items: center;

}

.mediaContent > div {
    flex: 1 1 50%; /* Cada elemento ocupará el 50% del ancho */
}

.iconText {
    color: var(--corporate);
}

.iconText:hover {
    cursor: pointer;
}

.imageVideoInputContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px; /* Espacio entre el input y el botón */
}

.imageVideoInputField {
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 1.1rem;
    width: 100%;
    resize: none;
    overflow: hidden;
    line-height: 1;
    padding: 0;
    min-height: 38px;
    flex: 1; /* Permite que el campo de entrada ocupe el espacio disponible */
}


.imageVideoInputButton {
    border: 1px solid #71767b;
    padding: 5px 20px;
    color: #71767b;
    border-radius: 30px;
    font-weight: 500;
    font-size: 15px;
    background-color: transparent; /* Fondo transparente */
    cursor: pointer;
    transition: background-color 0.3s ease;
    flex-shrink: 0; /* Evita que el botón se encoja */
    align-self: flex-start; /* Alinea el botón con la parte superior del contenedor */
}



.imageVideoInputButton:hover {
    background-color: #111111;
}

.imageVideoContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.mediaPreview {
    margin-bottom: 1rem;
    max-width: 100%;
}

.videoWrapper {
    position: relative;
    /* 16:9 Aspect Ratio */
    max-width: 100%;
    overflow: hidden;

    
}

.responsiveIframe {
    position: relative;
    top: 0;
    left: 0;
    max-width: 100%;

}

.responsiveImage {
    max-width: 100%;
    height: auto;
    border-radius: 15px;
}

.mediaWrapper {
    position: relative;
    display: inline-block;
}

.removeMediaButton {
    position: absolute;
    top: 5px;
    left: 5px;
    background-color: rgba(43, 44, 45);
    border: none;
    border-radius: 50%;
    padding: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.closeIcon {
    width: 12px;
}

.removeMediaButton:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

.modalContent {
    position: absolute;
    top: 5%; /* Changed from 50% to a fixed distance from the top */
    left: 50%;
    transform: translateX(-50%); /* Only transform horizontally */
    width: 600px;
    max-height: 80vh; /* Set a maximum height */
    background-color: #000000;
    border: none;
    outline: none;
    border-radius: 15px;

    padding-left: 15px;
    padding-right: 16px;
    padding-top: 13px;
    padding-bottom: 10px;

    display: flex;
    flex-direction: column;

}

.header {
    display: flex;
    flex-direction: row;

    justify-content: space-between;
}

.header img {
    height: 16px;
}

.closeIcon {
    color: white;
    font-size: 21px;
}

.replyTargetContainer {
    display: flex;
    margin-top: 30px;
    gap: 16px;
}

.inputField {
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 1.25rem;
    font-weight: 400;
    width: 100%;
    resize: none;
    overflow: hidden;
    line-height: 1.4;
    padding: 0;
    min-height: 38px;

}

.marginTop2 {
    margin-top: 0.5rem;
    /* Equivalente a mt-2, que es 8px */
    cursor: pointer;
}

.inputContainer {
    padding-top: 2.5rem;
    /* Equivalente a py-10, que es 40px */
    padding-bottom: 0.75rem;
    /* Equivalente a py-10, que es 40px */

    display: flex;
    /* Establece el contenedor como un flexbox */
    gap: 0.9rem;
    /* Espaciado de 20px entre elementos */


}

.marginTop2 p {
    margin-bottom: 0.5rem;
    /* Equivalente a mb-2, que es 8px */
    padding: 0;
    /* Equivalente a p-0, sin padding */
}

.formContainer {
    width: 100%;
    flex-grow: 1; /* Permite que este contenedor crezca */
}

 /* Nuevo estilo para el contenedor del textarea */
 .textareaContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.footerContainer {
    display: flex;
    /* Activa el modelo de caja flexible */
    justify-content: space-between;
    /* Distribuye el espacio entre los elementos, dejando espacio entre ellos */
    align-items: center;
    /* Centra verticalmente los elementos dentro del contenedor */
    margin-top: 1.25rem;
    /* Espacio superior equivalente a mt-5 en Tailwind (20px) */
}

.postReplyButton {
    border-radius: 100px;
    padding: 6px 18px;
    background-color: var(--corporate);
    color: white;
    text-align: center;
    cursor: pointer;
}

.mobilePostReplyButton {
    border-radius: 100px;
    padding: 4px 16px;
    background-color: var(--corporate);
    color: white;
    text-align: center;
    cursor: pointer;

    font-size: 15px;
    display: none;
}

.buttonsContainer {
    display: flex;
    gap: 12px;
}

.icon {
    color: var(--corporate);
    font-size: 22px;
}



/* Ocupa toda la pantalla */
@media (max-width : 702px) {
    .modalContent {
        height: 100vh;
        width: 100vw;
        top: 0px;
        left: 0;
        transform: translate(0%, 0%);
        border-radius: 0px;
        max-height: none; /* Remove max-height for full-screen on mobile */

        padding-block: 0px;
        padding-inline: 17px;

        align-items: center;


        
    }

    .contentWrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;

        max-width: 570px;

        padding-top: 11px;
    }

    .postReplyButton {
        display: none;
    }

    .mobilePostReplyButton {
        display: block;
    }

}