.pieChartComponent {
    width: 280px;
    height: auto;

    padding-top: 10px;


    display: flex;

    flex-direction: column;

    text-align: center;

    border-radius: 5px;

    padding-inline: 10px;

    padding-bottom: 10px;

    border: solid 1px rgb(80, 80, 80);

    color: white;
}

.chartTitle {
    font-weight: bold;
    font-size: 18px;
}

.registry {
    display: flex;
    flex-direction: row;

    border-radius: 5px;


    justify-content: space-between;

    padding-inline: 20px;
    padding-block: 5px;

    cursor: pointer;
}

.registry:hover {
    background-color: rgb(65, 65, 65);
}

.square {
    width: 18px;
    height: 18px;
    border-radius: 3px;
}

.colorContainer {
    padding: 10px;
}

.colorAndGroupContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    font-weight: normal;
}

.registryContainer {
    display: flex;
    flex-direction: column;

    font-weight: bold;
}

.registry p {
    margin: 0/* Esto no será necesario en world order, está ya configurado */
}