.privacyContainer {

    color: white;
    height: 100%;
    background-color: white;

}

html {
    scroll-behavior: smooth;
}

.titleContainer {
    padding-inline: 50px;
    padding-top: 130px;

    padding-bottom: 140px;

    background-color: white;
}

.titleContainer p {
    font-weight: bold;
    font-size: 140px;
    color: #14171a;
}

.effectiveContainer {
    background-color: #14171a;
    color: white;
    height: 110px;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.effectiveContainer p {
    font-weight: bold;
}

.privacyPolicyContent {
    color: #14171a;

    display: flex;
    flex-direction: row;

    padding-top: 7.5%;
}

.sections .sectionTitle {
    font-weight: bold;
    font-size: 35px;
    color: black; /* Asegúrate de que el color negro se aplique */
}


.sections {
    width: 65%;

    line-height: 1.5;

    padding-bottom: 40px;
    padding-right: 20%;


}

.sections a {
    color: var(--corporate);
    text-decoration: underline;
}

.sections p {
    color: #657786;
    margin-bottom: 25px;
}

.sections li {
    color: #657786;
}

.sections hr {
    margin-bottom: 60px;
}

.sections ul {
    margin-bottom: 30px;
}



.sectionLinks {
    width: 35%;
    display: flex;
    justify-content: flex-end;

    box-sizing: border-box;

    padding-right: 70px;

    padding-top: 12px;
    
}

.sectionLinks ul {
    list-style-type: none; /* Eliminar los puntos de la lista */
    padding-left: 0; /* Eliminar el padding de la izquierda */
}

.sectionLinks li {
    margin-bottom: 10px; /* Espacio entre los elementos de la lista */
}

.sectionLinks a {
    color: var(--corporate); /* Color de los enlaces */
    text-decoration: none; /* Sin subrayado */
    font-weight: bold;

    font-size: 14px;
}

.sectionLinks a:hover {
    text-decoration: underline; /* Subrayado al pasar el mouse */
}

@media (max-width : 1020px) {

    .sectionLinks {
        justify-content: flex-start;
        padding-right: 25px;
        padding-left: 25px;
    }

    .sections {
        padding-right: 2.5%;
    }

    .titleContainer p {
        font-size: 120px;
    }

}

@media (max-width : 600px) {
    .sectionLinks {
        display: none;
    }

    .sections {
        width: 100%;
        padding-right: 10%;
        padding-left: 4%;
    }

    .sections .sectionTitle {
        font-size: 22px;
    }

    .titleContainer p {
        font-size: 100px;
    }
}

@media (max-width : 480px) {
    .titleContainer p {
        font-size: 80px;
    }
}
