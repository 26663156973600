.emailDetails {
    display: flex;
    flex-wrap: wrap; 
    gap: 10px;
}

.emailDetail {
    padding-block: 5px;
    padding-inline: 10px;
    border: 1px solid #202327;
    cursor: pointer;
}

.emailDetail:hover {
    background-color: #141414;
}

.subtitle {
    font-size: 18px;
    font-weight: 500;
    color: #93979b;
}

.username {
    color: #71767b;
    font-size: 14px;
}

.organizationAndUserSender {
    display: flex;
    flex-direction: column;
}


.organizationTargetContainer {
    display: flex;
    gap: 10px;
    width: fit-content; /* New: ensures container only takes up content width */
    padding-left: 10px;
    padding-right: 15px;
    padding-top: 10px;
    border-radius: 5px;


    cursor: pointer;
}

.organizationTargetContainer img {
    height: 50px;
    width: 50px;
    border-radius: 30px;
    
}

.signatureContainer {
    display: flex;
    gap: 10px;
    width: fit-content; /* New: ensures container only takes up content width */
    padding-left: 10px;
    padding-right: 15px;
    padding-top: 10px;
    border-radius: 5px;

    cursor: pointer;

    align-self: flex-end;
}


.signatureContainer:hover {
    background-color: #141414;
}

.signatureContainer img {
    height: 40px;
    width: 40px;
    border-radius: 30px;
    
}


.signature {
    font-family: "Mrs Saint Delafield", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 40px;
}


.optionsContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.optionsContainer button {
    
    max-width: 200px;
    width: 50%;
    height: 40px;
    border-radius: 3px;
}

.optionsContainer .optionSelectable {
    font-weight: bold;
}

.optionsContainer .optionSelectable:hover {
    opacity: 0.8;
}

.optionsContainer .optionUnselectable {
    opacity: 0.6;
    cursor: auto;
}

.optionsContainer .accept {
    background-color: var(--corporate);
}

.optionsContainer .decline {
    background-color: rgb(209, 49, 49);
}

.deadline {
    font-size: 14px;
    color: #71767b;
}

.optionsButtons {
    display: flex;
    gap: 10px;
}


.composeEmail {
    display: flex;
    padding-inline: 20px;
    margin-bottom: 13px;


}

.composeEmail button {
    background-color: var(--corporate);
    width: 100%;
    height: 40px;
    border-radius: 10px;
}

.writeMessageContainer {
    position: sticky;
    bottom: 0px;
    left: 0;
    right: 0;
    width: 100%;
    background-color: black;
    padding-bottom: 19px;
    padding-top: 10px;
    margin-top: auto; /* Empuja el contenedor al final */
    z-index: 10;

    border-top: 1px solid var(--border);
}

.writeMessage {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: calc(100% - 40px); /* Resta el padding para mantener el ancho alineado */
    margin: 0 auto;
    border-radius: 10px;
    padding: 15px 20px;
    background-color: #202327;
}

.searchIconWrapper {
    display: flex;
    align-items: center;
}

.writeMessageIcon {
    height: 15px;
    width: auto;
}

.messageInput {
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 1rem;
    width: 100%;
}

.messageInput::placeholder {
    color: #71767b;
}



/* Contenedor principal de mensajes con scroll */
.chatMessagesContainer {
    display: flex;
    justify-content: center; 

}

.emailContentContainer {
    height: calc(100vh - 57px); /* Adjust based on header height */
    overflow-y: auto; /* Enable scrolling here */
    position: relative;
}

/* Wrapper que contiene los mensajes y el input */
.chatMessagesWrapper {
    display: flex;
    flex-direction: column;
    width: 675px;
    height: 100%;
    position: relative;
    overflow-y: none;
    
}

.messagesContainer {
    display: flex;
    flex-direction: column;
    padding-top: 10px;

    gap: 10px;
}

/* Necesitamos asegurarnos que el contenedor del mensaje individual sea flex */
.chatMessagesWrapper>div {
    display: flex;
    flex-direction: column;
}

.dateHeader {
    text-align: center;
    margin: 10px auto;
    font-weight: 700;
    font-size: 15px;
    width: fit-content; /* Hace que el elemento tome el ancho de su contenido */
    min-width: 100px;
    padding-inline: 7px;
    padding-block: 2px;
    background-color: #141414;
    border-radius: 30px;
    display: block; /* En lugar de flex */
}

.messageBubble {
    padding: 10px;
    margin: 5px 0;
    border-radius: 10px;
    max-width: 450px;
    color: white;
    line-height: 22px;
    display: flex;
    background-color: #333; /* Asegurando el contraste del fondo */
    word-wrap: break-word; /* Asegura que el contenido largo se divida en líneas */
    width: fit-content; /* Se ajusta al contenido */
}


.messageAsSender {
    background-color: var(--corporate);
    margin-left: auto; /* Empuja el mensaje hacia la derecha */
    justify-content: flex-end;
}

.messageAsReceiver {
    background-color: #2f3336;
}






.pageContainer {
    height: 100vh;
    overflow: hidden;
    background-color: #1b1b1b;
}

.layoutContainer {
    display: flex;
    height: 100%;
}

.chatsList {
    width: 430px;
    /* Ancho fijo para la sección de chats */
    border-right: 1px solid var(--border);
    display: flex;
    flex-direction: column;
    background-color: #000000;
    border-left: 1px solid var(--border);
}

.chatsContent {
    flex: 1;
    overflow-y: auto;
}

.mainContent {
    flex: 1;
    /* Toma el espacio restante */
    overflow-y: auto;
    background-color: black;
}

.chatContentHeader {
    border-bottom: 1px solid var(--border);
    justify-content: space-between;
    font-weight: bold;
    font-size: 18px;
    padding-inline: 10px;
    padding-right: 10px;
    padding-block: 8px;
    background-color: rgba(0, 0, 0, 0.6);
}

.mailTarget {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    cursor: pointer;
    padding-block: 5px;
    padding-inline: 10px;

    font-size: 17px;
}

.mailTarget:hover {
    background-color: var(--border);
}

.chatContentHeader>div img {
    height: 32px;
    width: 32px;
    border-radius: 20%;
}

.infoIcon {
    position: relative;
    width: 38px;
    /* Aumentamos un poco el ancho del contenedor */
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.infoIcon img {
    width: 21px;
    height: 21px;
}

.infoIcon::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: transparent;
    transition: background-color 0.3s ease;
    z-index: -1;
}

.infoIcon:hover::before {
    background-color: #1b1b1b;
}

.chatContentHeader>div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.messagesHeader {
    justify-content: space-between;
    font-weight: bold;
    font-size: 20px;
    padding-inline: 15px;
    padding-block: 10px;
}

.messagesHeader div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 19px;
}

.messagesHeader img {
    height: 18px;
}


.chatContentHeader,
.messagesHeader {
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: #000000;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    color: white;
    width: 100%;
}

.tab {
    cursor: pointer;
    opacity: 0.6;
    font-size: 16px;
    transition: all 0.1s ease;
}

.activeTab {
    opacity: 1;
    font-size: 20px;
    font-weight: bold;
}




.noChatSelected {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.noChatSelected button {
    background-color: var(--corporate);
    font-size: 18px;
    font-weight: bold;
    border-radius: 30px;
    height: 52px;
    padding-inline: 32px;
    margin-top: 25px;
}

.selectAMessage {
    font-weight: bold;
    font-size: 32px;
    margin-bottom: 3px;
}

.noChatSelectedDescription {
    color: #71767b;
    line-height: 21px;
}


.searchBar {
    display: flex;
    flex-direction: row;
    gap: 10px;

    width: 94%;
    align-self: center;

    border-radius: 30px;
    padding-block: 10px;
    padding-inline: 20px;
    margin-top: 15px;
    margin-bottom: 13px;
    align-items: center;

    box-shadow: 0 0 0 1px var(--border);
}

.searchBar:focus-within {
    box-shadow: 0 0 0 2px var(--corporate);
}

.searchBar input {
    background-color: transparent;
    outline: none;
    font-size: 15px;
    width: 340px;
}

.searchBar img {
    height: 15px;
}

.searchBar input::placeholder {
    color: #71767b;
}

.backButton {
    display: none;
    align-items: center;
    background: transparent;
    border: none;
    color: white;
    padding: 0 5px;
    cursor: pointer;
    height: 100%;
}

.arrowIcon {
    cursor: pointer;
    margin-top: 1px;
}


.subject {
    font-size: 25px;
    font-weight: 500;

    line-height: 30px;
}

.dateTime {
    color: #71767b;
    font-weight: normal;
    align-self: flex-end;
}


@media(max-width : 1500px){
    .chatMessagesContainer {
        padding-inline: 4%;
    }
}


/* Mantén todo el CSS anterior y añade estas reglas al final */

@media (max-width: 1125px) {
    .chatsList {
        width: 100%;
        border-right: none;
    }



    .mainContent {
        width: 100%;
        border-left: 1px solid var(--border);

    }

    .layoutContainer {
        position: relative;
    }

    /* Cuando hay un chat seleccionado */
    .layoutContainer[data-has-chat="true"] .chatsList {
        display: none;
    }

    /* Cuando no hay chat seleccionado */
    .layoutContainer[data-has-chat="false"] .mainContent {
        display: none;
    }

    /* Añadir botón de regreso */
    .backButton {
        display: flex;
    }

}

@media (max-width: 675px) {
    .messageBubble {
        width: 95%;
    }
}

@media (max-width: 499px) {
    .mainContent {
        padding-bottom: 70px;
    }
}