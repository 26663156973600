.menuButton {
    display: none;

    position: fixed;
    bottom: 20px;
    right: 40px;
    background-color: black;
    border-radius: 30px;
    padding: 17px;

    /* Sombra blanca más sutil y uniforme */
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.4); 
    /* Opcional: puedes ajustar la opacidad o difuminado */
    border: none;
    transition: box-shadow 0.3s ease;
}

.menuButtonIcon {
    color: white;
    font-size: 27px;
}

/* Sombra más suave al hacer hover */
.menuButton:hover {
    box-shadow: 0px 0px 15px rgba(white, 0.5);
}

@media (max-width : 768px){
    .menuButton {
        bottom: 74px;
        right: 20px;

        padding: 15px;
    }
}


@media(max-width : 1400px) {
    .displayUpTo1400 {
        display: block;
    }
}
