.bankName {
  font-size: 20px;
  font-weight: 500;
  max-width: 100%; /* Asegura que el texto no se desborde */
  word-wrap: break-word; /* Permite que las palabras largas se rompan si es necesario */
}

.bankUsername {
  font-size: 15px;
  font-weight: 400;
  color: #797b7e;
}

.openAccount {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 10px;
  background-color: #26282b;
  width: 300px; /* Aumentado para mantener consistencia */
  height: 200px; /* Aumentado para mantener consistencia */
  cursor: pointer;
}

.add {
  background-color: #3a3d3f;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 75px;
  border-radius: 50%;
}

.add .icon {
  font-size: 40px;
}

.openAccount:hover {
  background-color: #1f1f20;
}

.accountHeader {
  display: flex; 
  justify-content: space-between;
  flex: 0 0 auto;
  width: 100%;
  align-items: flex-start; /* Alinea los elementos al inicio */
  gap: 10px;
}

.countryFlag {
  height: 25px;
  border-radius: 2.5px;
  flex-shrink: 0; /* Evita que la bandera se encoja */
}

.pageWrapper {
  height: 100vh;
  overflow: hidden;
  background-color: black;
}

.pageContainer {
  height: 100vh;
  overflow-y: auto;
  background-color: black;
  border-left: 1px solid var(--border);
}

.sidebar {
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
  border-bottom: 1px solid var(--border);
  background-color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 11px 30px;
  gap: 5px;
  color: white;
}

.accountsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  gap: 15px;
  padding: 30px 200px;
}

.account {
  display: flex;
  flex-direction: column;
  gap: 15px; /* Aumentado el gap para mejor espaciado */
  padding: 15px; /* Aumentado el padding para mejor espaciado */
  border-radius: 10px;
  background-color: #26282b;
  width: 300px; /* Aumentado para acomodar nombres más largos */
  height: auto; /* Aumentado para acomodar todo el contenido */
  cursor: pointer;
}

.account:hover {
  background-color: #1f1f20;
}

.accountBank {
  display: flex;
  flex-direction: row;
  align-items: flex-start; /* Cambiado a flex-start para mejor alineación */
  gap: 10px;
  flex: 1;
}

.accountBank img {
  border-radius: 5px;
  height: 55px;
  width: 55px;
  flex-shrink: 0; /* Evita que la imagen se encoja */
}

.accountBank > div {
  flex: 1; /* Permite que el contenedor del texto ocupe el espacio disponible */
  min-width: 0; /* Permite que el texto se ajuste */
}

.accountContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  justify-content: center;
}

.balance {
  font-size: 20px;
  font-weight: 700;
  display: flex;
  align-items: baseline;
  gap: 4px;
}

.balance span {
  font-size: 15px;
  font-weight: 400;
}

.account button {
  background-color: var(--corporate);
  width: 100%;
  padding-block: 5px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  margin-top: auto;
}

@media (max-width: 925px) {
  .accountsContainer {
    padding-inline: 50px;
  }
}

@media (max-width: 400px) {
  .accountsContainer {
    padding-inline: 20px;
  }
}