.mediaContent {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 0.5rem;
  align-items: center;

}

.mediaContent > div {
  flex: 1 1 50%; /* Cada elemento ocupará el 50% del ancho */
}

.imageVideoInputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px; /* Espacio entre el input y el botón */
}

.imageVideoInputField {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 1.1rem;
  width: 100%;
  resize: none;
  overflow: hidden;
  line-height: 1;
  padding: 0;
  min-height: 38px;
  flex: 1; /* Permite que el campo de entrada ocupe el espacio disponible */
}


.imageVideoInputButton {
  border: 1px solid #71767b;
  padding: 5px 20px;
  color: #71767b;
  border-radius: 30px;
  font-weight: 500;
  font-size: 15px;
  background-color: transparent; /* Fondo transparente */
  cursor: pointer;
  transition: background-color 0.3s ease;
  flex-shrink: 0; /* Evita que el botón se encoja */
  align-self: flex-start; /* Alinea el botón con la parte superior del contenedor */
}



.imageVideoInputButton:hover {
  background-color: #111111;
}

.imageVideoContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.mediaPreview {
  margin-bottom: 1rem;
  max-width: 100%;
}

.videoWrapper {
  position: relative;
  /* 16:9 Aspect Ratio */
  max-width: 100%;
  overflow: hidden;

  
}

.responsiveIframe {
  position: relative;
  top: 0;
  left: 0;
  max-width: 100%;

}

.responsiveImage {
  max-width: 100%;
  height: auto;
  border-radius: 15px;
}

.mediaWrapper {
  position: relative;
  display: inline-block;
}

.removeMediaButton {
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: rgba(43, 44, 45);
  border: none;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeIcon {
  width: 12px;
}

.removeMediaButton:hover {
  background-color: rgba(0, 0, 0, 0.7);
}



.socialContainer {
  width: 100%;
  min-height: 100vh;
  height: auto;
}

.formContainer {
  display: flex;
  gap: 1.25rem;
  padding: 20px;
  padding-top: 18px;
  padding-bottom: 13px;
  box-sizing: border-box;

  border-bottom: 1px solid var(--border);
}

.formContent {
  width: 100%;
}

.socialPostButton {
  width: 100%;
  border-radius: 100px;
  background-color: var(--corporate); /* Equivalente a bgcolor en MUI */
  color: white;
  text-align: center;
  cursor: pointer;
  padding: 5px 20px;
}

.mobileSocialPostButton {
  display: none;
}

.inputField {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 1.25rem; /* Equivalente a text-xl en Tailwind */
  width: 100%;
  resize: none;
  overflow: hidden;
  line-height: 1.5;
  padding: 0;
  min-height: 38px; /* Altura mínima para una línea de texto */
}

.errorText {
  color: #f87171; /* Equivalente a text-red-500 en Tailwind */
}

.inputAndError {
  display: flex;
  flex-direction: column;
}

.formActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
  gap: 1rem; /* Ajusta el espacio entre elementos */
}

.actionItems {
  display: flex;
  gap: 1.25rem; /* Equivalente a space-x-5 en Tailwind */
  align-items: center;
}

.iconText {
  color: var(--corporate);
}

.iconText:hover {
  cursor: pointer;
}

.headerSection {
  background-color: black;
  display: flex;
  align-items: center;
  position: sticky;
  padding-left: 15px;
  padding-bottom: 5px;
  padding-top: 13px;
}

.icon {
  cursor: pointer;
  margin-top: 1px;
}

.title {
  font-size: 1.25rem;
  font-weight: bold;
  opacity: 0.9;
  margin-left: 1.1rem;
}

.responsesTitle {
  margin-bottom: 1rem;
  font-size: 1.25rem;
  font-weight: bold;
  opacity: 0.9;
  margin-left: 1.25rem;
}

.selectedImage {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
}

.circularProgressContainer {
  display: flex;
  align-items: center;
  margin-left: auto;
}

@media (max-width : 420px){
  .socialContainer{
    width: 100vw;
  }

  .socialPostButton {
    display:none;
  }

  .mobileSocialPostButton {
    display: block;
  }
}
