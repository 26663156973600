.premiumLimitList {
    margin-top: 20px;
    color: #71767b;
}



/* Estilo del modal */
.modalStyle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: #000000;
    border: none;
    box-shadow: 24px;
    padding: 16px;
    outline: none;
    border-radius: 4px;
}

.modalContent {
    display: flex;
    flex-direction: column;
    height: 200px;
    overflow-x: hidden;
}


.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2%;
}

.headerLeft {
    display: flex;
    align-items: center;
    gap: 12px;
}

.headerText {
    color: white;
}

.headerText strong {
    font-size: 1.125rem;
    /* text-lg in Tailwind CSS */
}

.skillContainer {
    display: flex;
    flex-direction: row;
    padding: 10px;
    padding-left: 0px;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
}

.skillInfo {
    display: flex;
    flex-direction: column;
    width: 85px;
}

.levelUpButton {
    border: 1px solid #536471;
    border-radius: 100px;
    padding-block: 8px;
    width: 100px;
    transition: background-color 0.3s ease;
}

.levelUpButton:hover {
    background-color: #151616;
}