.formWrapper {
    max-height: 100vh;
    overflow-y: auto;
  }
  
  .formContainer {
    display: flex;
    flex-direction: column; /* Cambiado para que los elementos se apilen verticalmente por defecto */
    gap: 16px;
  }
  
  .inputGroup {
    display: flex;
    flex-direction: row; /* Cambiado para que los elementos se apilen verticalmente por defecto */
    gap: 16px;
    width: 100%;
  }
  
  .textField {
    flex: 1;
    min-width: 45%; /* Ancho mínimo en pantallas grandes */
  }
  
  @media (max-width: 620px) {
    .textField {
      min-width: 100%; /* Ancho completo en pantallas pequeñas */
    }
  
    .formContainer {
      flex-direction: column; /* Asegura que se apilen verticalmente en pantallas pequeñas */
    }
  
    .inputGroup {
      flex-direction: column; /* Asegura que se apilen verticalmente en pantallas pequeñas */
    }
  }
  
  .centerText {
    text-align: center;
    margin-top: 20px;
  }
  
  .importantNotice {
    margin: 5px auto;
    color: red;
    text-align: center;
  }
  
  .submitButton {
    text-transform: none;
    background-color: var(--corporate);
    border-radius: 29px;
    padding: 10px 0;
    width: 100%;
  }
  