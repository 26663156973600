.writeMessageContainer {
    position: sticky;
    bottom: 0px;
    left: 0;
    right: 0;
    width: 100%;
    background-color: black;
    padding-bottom: 19px;
    padding-top: 10px;
    margin-top: auto; /* Empuja el contenedor al final */
    z-index: 10;

    border-top: 1px solid var(--border);
}

.writeMessage {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: calc(100% - 40px); /* Resta el padding para mantener el ancho alineado */
    margin: 0 auto;
    border-radius: 10px;
    padding: 15px 20px;
    background-color: #202327;
}

.searchIconWrapper {
    display: flex;
    align-items: center;
}

.writeMessageIcon {
    height: 15px;
    width: auto;
}

.messageInput {
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 1rem;
    width: 100%;
}

.messageInput::placeholder {
    color: #71767b;
}



/* Contenedor principal de mensajes con scroll */
.chatMessagesContainer {
    display: flex;
    padding-inline: 10%;
    padding-block: 15px;
    justify-content: center;
    height: calc(100vh - 60px); /* Ajusta el 60px según el alto de tu header */

}

/* Wrapper que contiene los mensajes y el input */
.chatMessagesWrapper {
    display: flex;
    flex-direction: column;
    width: 675px;
    height: 100%;
    padding-inline: 0.75%;
    position: relative;
    overflow-y: none;
}

.messagesContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px; /* Espacio entre los mensajes y el input */

}

/* Necesitamos asegurarnos que el contenedor del mensaje individual sea flex */
.chatMessagesWrapper>div {
    display: flex;
    flex-direction: column;
}

.dateHeader {
    text-align: center;
    margin: 10px auto;
    font-weight: 700;
    font-size: 15px;
    width: fit-content; /* Hace que el elemento tome el ancho de su contenido */
    min-width: 100px;
    padding-inline: 7px;
    padding-block: 2px;
    background-color: #141414;
    border-radius: 30px;
    display: block; /* En lugar de flex */
}

.messageBubble {
    padding: 10px;
    margin: 5px 0;
    border-radius: 10px;
    max-width: 450px;
    color: white;
    line-height: 22px;
    display: flex;
    background-color: #333; /* Asegurando el contraste del fondo */
    word-wrap: break-word; /* Asegura que el contenido largo se divida en líneas */
    width: fit-content; /* Se ajusta al contenido */
}


.messageAsSender {
    background-color: var(--corporate);
    margin-left: auto; /* Empuja el mensaje hacia la derecha */
    justify-content: flex-end;
}

.messageAsReceiver {
    background-color: #2f3336;
}






.pageContainer {
    height: 100vh;
    overflow: hidden;
    background-color: #1b1b1b;
}

.layoutContainer {
    display: flex;
    height: 100%;
}

.chatsList {
    width: 430px;
    /* Ancho fijo para la sección de chats */
    border-right: 1px solid var(--border);
    display: flex;
    flex-direction: column;
    background-color: #000000;
    border-left: 1px solid var(--border);
}

.chatsContent {
    flex: 1;
    overflow-y: auto;
}

.mainContent {
    flex: 1;
    /* Toma el espacio restante */
    overflow-y: auto;
    background-color: black;
}

.chatContentHeader {
    border-bottom: 1px solid var(--border);
    justify-content: space-between;
    font-weight: bold;
    font-size: 18px;
    padding-inline: 15px;
    padding-block: 8px;
    background-color: rgba(0, 0, 0, 0.6);
}

.chatContentHeader>div>img {
    height: 40px;
    width: 40px;
    border-radius: 30px;
}

.infoIcon {
    position: relative;
    width: 38px;
    /* Aumentamos un poco el ancho del contenedor */
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.infoIcon img {
    width: 21px;
    height: 21px;
}

.infoIcon::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: transparent;
    transition: background-color 0.3s ease;
    z-index: -1;
}

.infoIcon:hover::before {
    background-color: #1b1b1b;
}

.chatContentHeader>div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.messagesHeader {
    justify-content: space-between;
    font-weight: bold;
    font-size: 20px;
    padding-inline: 15px;
    padding-block: 10px;
}

.messagesHeader div {
    display: flex;
    flex-direction: row;
    gap: 19px;
}

.messagesHeader img {
    height: 18px;
}


.chatContentHeader,
.messagesHeader {
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: #000000;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    color: white;
    width: 100%;
}


.noChatSelected {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.noChatSelected button {
    background-color: var(--corporate);
    font-size: 18px;
    font-weight: bold;
    border-radius: 30px;
    height: 52px;
    padding-inline: 32px;
    margin-top: 25px;
}

.selectAMessage {
    font-weight: bold;
    font-size: 32px;
    margin-bottom: 3px;
}

.noChatSelectedDescription {
    color: #71767b;
    line-height: 21px;
}


.searchBar {
    display: flex;
    flex-direction: row;
    gap: 10px;

    width: 94%;
    align-self: center;

    border-radius: 30px;
    padding-block: 10px;
    padding-inline: 20px;
    margin-top: 15px;
    margin-bottom: 13px;
    align-items: center;

    box-shadow: 0 0 0 1px var(--border);
}

.searchBar:focus-within {
    box-shadow: 0 0 0 2px var(--corporate);
}

.searchBar input {
    background-color: transparent;
    outline: none;
    font-size: 15px;
    width: 340px;
}

.searchBar img {
    height: 15px;
}

.searchBar input::placeholder {
    color: #71767b;
}

.backButton {
    display: none;
    align-items: center;
    background: transparent;
    border: none;
    color: white;
    padding: 0 15px;
    cursor: pointer;
    height: 100%;
}

.arrowIcon {
    cursor: pointer;
    margin-top: 1px;
}

@media(max-width : 1400px){
    .chatMessagesContainer {
        padding-inline: 4%;
    }
}


/* Mantén todo el CSS anterior y añade estas reglas al final */

@media (max-width: 1125px) {
    .chatsList {
        width: 100%;
        border-right: none;
    }

    .mainContent {
        width: 100%;
    }

    .layoutContainer {
        position: relative;
    }

    /* Cuando hay un chat seleccionado */
    .layoutContainer[data-has-chat="true"] .chatsList {
        display: none;
    }

    /* Cuando no hay chat seleccionado */
    .layoutContainer[data-has-chat="false"] .mainContent {
        display: none;
    }

    /* Añadir botón de regreso */
    .backButton {
        display: flex;
    }

}

@media (max-width: 675px) {
    .messageBubble {
        width: 95%;
    }
}