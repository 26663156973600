.socialMediaCard {
    display: flex;
    flex-direction: row;
    gap: 15px;
    width: 330px;
    flex-wrap: wrap;
}

.socialMediaButton {
    border: 1px solid var(--border);
    border-radius: 30px;
    padding: 10px;
    color: var(--primary);
}

.socialMediaButton:hover {
    background-color: var(--hover);
    cursor: pointer;
}

.container {
    padding-top: 5px;

    display: flex;
    flex-direction: column;

    gap: 15px;

    width: 330px;

}

.searchBar {
    display: flex;
    flex-direction: row;
    gap: 10px;

    width: 100%;

    background-color: black;
    border: 1px solid var(--border);
    border-radius: 30px;
    padding-block: 8px;
    padding-inline: 20px;
}

.searchBar input {
    background-color: black;
    outline: none;
    /* Elimina el contorno blanco por defecto */

}

.searchBar input::placeholder {
    color: #71767b;

}

.searchIcon {
    color: #71767b;
}

.card {
    display: flex;
    flex-direction: column;

    border: 1px solid var(--border);
    border-radius: 15px;

    padding-block: 7px;
    padding-bottom: 15px;

}

.card .showMore {
    padding-top: 15px;
    padding-inline: 15px;
    width: fit-content;
    color: #0bac66;
}

.profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-inline: 15px;
    padding-block: 13px;
    gap: 7px;
}

.profile:hover {
    background-color: #0f0f0f;
    cursor: pointer;
}

.profile button {
    background-color: white;
    color: black;
    border-radius: 30px;
    padding-inline: 15px;
    padding-block: 6px;
    margin-left: auto;
    font-size: 14px;
    font-weight: 600;
}

.profile img {
    height: 40px;
    border-radius: 30px;
}

.profileInfo {
    display: flex;
    flex-direction: column;
    gap: 0px;


    line-height: 20px;
}

.profileInfo .name {
    font-weight: bold;
}

.profileInfo .name:hover {
    text-decoration: underline;
}

h1 {
    font-weight: bold;
    font-size: 21px;

    padding-inline: 15px;
    padding-bottom: 7px;
}

.username {
    color: var(--secondary);
    font-size: 15px;
}

.trendingItem {
    display: flex;
    flex-direction: column;
    padding-inline: 15px;
    padding-block: 13px;
}

.trendingItem:hover {
    background-color: #0f0f0f;
    cursor: pointer;
}

.trendingItem .name {
    font-weight: bold;
}

.trendingItem .trendCategoryAndRank {
    font-size: 14px;
    color: var(--secondary);
}

.horizIcon {
    font-size: 20px;
    color: var(--secondary);
}


@media(max-width : 1100px) {
    .container {
        width: 280px;
    }

    .username {
        width: 60px;
    }
}

@media(max-width : 1030px) {
    .container {
        display: none;
    }
}