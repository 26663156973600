
.mobileNavigationContainer {
  display: none;

  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  height: 53px;
  position: fixed;
  bottom: 0;
  background-color: #000; /* Puedes ajustar el color de fondo según tus necesidades */
  border-top: 1px solid #2d3033;
  z-index: 1000; /* Asegúrate de que esté por encima de otros elementos */
  transition: transform 0.5s ease-in-out;
}

.visible {
  transform: translateY(0);
}

.hidden {
  transform: translateY(100%);
}


.worldOrderLogo {
  margin-top: 10px;
  margin-left: 17px;
  margin-bottom: 20px;
}

.navigation {
  width: 270px;
  /* Ajusta este valor según tus necesidades */
  background-color: #000000;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 100vh;
  /* Ajusta esto según la altura del header */
  position: fixed;
  /* Ajusta esto según la altura del header */
  left: 0;
  overflow-y: auto;
  /* Permite desplazamiento en caso de que el contenido del menú sea mayor que la altura disponible */
}


.menuItem {
  display: flex;
  align-items: center;
  padding: 10px;
  padding-top: 7px;
  padding-left: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 100px;
  margin-bottom: 9px;
}

.menuItem:hover {
  background-color: var(--hover);
}

.menuText {
  margin-left: 15px;
  font-size: 20px;
}

.boldText {
  margin-left: 10px;
  font-weight: bold;
}

.publishMenuButton {
  width: 215px;
  height: 52px;
  border-radius: 29px;
  background-color: var(--corporate);
  align-self: center;
  margin-top: 15px;
  margin-right: 3px;

  font-size: 16.2px;
  color: #E7E9EA;
}

.navigationButton {
  display: none;
  width: 50px;
  /* Ajusta el ancho del botón */
  height: 50px;
  /* Ajusta la altura del botón */
  background-color: #171717;
  /* Cambia el color del botón */
  color: white;
  /* Cambia el color del icono */
  border: none;
  /* Elimina el borde del botón */
  border-radius: 50%;
  /* Crea un botón redondo */
  position: fixed;
  /* Fija el botón en la posición */
  bottom: 20px;
  /* Ajusta la distancia del botón al borde inferior */
  right: 20px;
  /* Ajusta la distancia del botón al borde derecho */
  cursor: pointer;
  /* Muestra el cursor como pointer al pasar por encima */
  justify-content: center;
  /* Centra el icono horizontalmente */
  align-items: center;
  /* Centra el icono verticalmente */
}


.customButtonClass {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px;
}


.profileNavigation {
  display: flex;
  align-items: center;
  margin-top: auto;
  /* Empuja el perfil hacia la parte inferior */
  margin-bottom: 4px;
  padding-left: 20px;
  /* Agrega un poco de padding para separación */
  padding-bottom: 10px;
  padding-top: 10px;
  padding-right: 5px;
  border-radius: 100px;
}

.profileNavigation:hover {
  background-color: var(--elegant-hover);
  /* Color más claro que el negro */
}

.customButtonClass {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: auto;
  width: 40px;
}

.profileDetails {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  /* Ajusta este margen según sea necesario */
  line-height: 1.3;
}

.navigationName {
  font-size: 15px;
}

.navigationUsername {
  color: #6b7280;
}


.customButtonClass:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

@media (max-width: 1279px) {

  .worldOrderLogo {
    align-self: center;
    margin-left: 0px;
  }

  .profileNavigation {
    align-self: center;
    margin-top: auto;
    /* Empuja el perfil hacia la parte inferior */
    margin-bottom: 4px;
    padding-left: 0px;
    /* Agrega un poco de padding para separación */
    padding-bottom: 10px;
    padding-top: 10px;
    padding-right: 0px;
    border-radius: 100px;

    cursor: pointer;
  }

  .menuText {
    display: none;
  }

  .navigationContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }


  .menuItem {
    display: flex;
    align-items: center;
    justify-content: center;
    /* Asegura que el contenido esté centrado horizontalmente */
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 100px;
    margin-bottom: 10px;
    width: 100%;
    /* Ajusta esto si es necesario */
  }

  .publishMenuButton {
    display: none;
  }

  .profileDetails {
    display: none;
  }

  .customButtonClass {
    display: none;
  }

  .navigation {
    width: 85px;
    overflow-y: scroll;

    left: 5px;
  }

  .navigation::-webkit-scrollbar {
    display: none;
    /* Ocultar scrollbar en Chrome, Safari y Edge */
  }


}

/* @media (max-width: 768px) { */
@media (max-width: 499px) {
  .navigation {
    display: none;
  }

  .mobileNavigationContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  .menuItem {
    flex-direction: column;
    padding: 5px;
    margin: 0;
    justify-content: center;
    align-items: center;
  }

  .menuText {
    display: block;
    margin: 0;
    font-size: 12px;
    text-align: center;
  }

  .profileNavigation {
    display: none;
  }

  .publishMenuButton {
    display: none;
  }

  .navigation::-webkit-scrollbar {
    display: none;
  }
}