.modalBox {
    background: radial-gradient(ellipse 80% 45% at top center, #1b352d 10%, #000000 70%);
    border-radius: 8px;

    width: 100vw;
    height: 100%;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    border: none;
    outline: none;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;

    z-index: 1000;
}


.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.closeIcon {
    color: white;
}

.closeIconContainer {
    width: fit-content; /* Ajusta el ancho al contenido */
    display: flex; /* Mantén el display como flex */
    padding: 14px; /* Opcional: Añade un poco de padding para espaciar el contenido */
    border-radius: 4px; /* Opcional: Redondea los bordes */

    align-self: flex-start;
}


.modalTitle {
    font-size: 4rem;
    font-weight: bold;
    color: #e7e9ea;

    margin-inline: 20px;

    text-align: center;

    margin-top: 58px;
}


.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1; /* Permite que crezca y ocupe el espacio disponible */
    overflow-y: auto; /* Habilita el scroll si el contenido se desborda */
    max-height: calc(100% - 133px); /* Ajusta la altura máxima según el tamaño disponible */


    box-sizing: border-box;
    margin-bottom: 109px;
    padding-bottom: 50px;
    
}

.modalSubtitle {
    font-size: 1.1rem;
    color: #a6aaad;
    text-align: center;
    margin-inline: 20px;
}

.plansContainer {
    display: flex;
    flex-direction: row;
    gap: 2.25rem;
    margin-top: 50px;
}

.premiumPlan {
    background-color: #202327;
    width: 330px;
    padding: 28px;
    border-radius: 10px;
}

.premiumPlan:hover {
    cursor: pointer;
}

.premiumPlan button {
    background-color: white;
    color: #000000;
    font-weight: bold;
    padding: 5px;
    border-radius: 30px;
    width: 100%;
    margin-bottom: 20px;
    display: none;
}

.selectedPremiumPlan {
    outline: 2px solid #088a51;
    box-sizing: border-box;
}

.premiumPlan .title {
    font-size: 1.25rem;
}

.premiumPlan .monthlyPrice {
    font-size: 2.4rem;
    font-weight: bold;
}

.premiumPlan .billedAnually {
    margin-bottom: 18px;
}



.planHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.planTitle {
    font-size: 1.25rem;
    font-weight: bold;
    color: white;
}


.footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: black;
    border-top: 1px solid #a6aaad;
    padding: 10px;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 3rem;

    height: 133px;
}


.footer button {
    background-color: #088a51;
    font-weight: bold;
    border-radius: 30px;
    padding-block: 7px;
    width: 100%;
}

.premiumTypeAndPrice {
    width: 165px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.premiumTypeAndPrice .premiumType {
    font-size: 1.25rem;
}

.premiumTypeAndPrice .price {
    font-weight: bold;
    font-size: 26px;
}

.subscribeAndTermsContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 470px;
    padding-top: 5px;

    text-align: center;
}

.termsText {
    font-size: 0.85rem;
    color: #b3b3b3;

    border: 1px solid #b3b3b3;
    border-radius: 5px;
    padding: 3px;
}

@media (max-width : 600px){

    .premiumPlan {
        width: 100%;
    }

    .plansContainer {
        width: 94%;
    }

}

@media (max-width : 780px){
    .plansContainer {
        flex-direction: column;
    }

    .premiumPlan button {
        display: block;
    }

    .footer {
        display: none;
    }

    .content {
        margin-bottom: 0px;
        max-height: 100%;
    }

    .selectedPremiumPlan {
        outline: none;
    }
}

@media (max-width : 1600px){

    .modalTitle {
        font-size: 3rem;
        margin-top: 0px;
        line-height: 3.75rem;
        margin-bottom: 12px;
    }

    .plansContainer {
        margin-top: 40px;
    }

}
