.listContainer {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid var(--border);
}

.listItem {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    padding-inline: 10px;
    padding-block: 5px;
}

.listItem img {
    height: 45px;
    width: 45px;
    border-radius: 50%;
}

.listItem div {
    display: flex;
    flex-direction: column;
}

.listItem:hover {
    background-color: var(--border);
}