.modalContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600;
    background-color: #1f1f1f;
    border: none;
    outline: none;
    box-shadow: 24px 24px 48px rgba(0, 0, 0, 0.12);
    padding-inline: 120px;
    padding-block: 30px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.modalContainerPaddingBottom {
    padding-bottom: 60px;
}

.incorrectPassword {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center; /* Opcional: Para centrar el texto dentro del div */
    padding: 5px; /* Opcional: Añade algo de padding si lo deseas */
    background-color: #f8d7da; /* Opcional: Añade un color de fondo si lo deseas */
    color: #721c24; /* Opcional: Color del texto */
    border-top: 1px solid #f5c6cb; /* Opcional: Añade un borde superior */
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  