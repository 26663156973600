.mediaContent {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 0.5rem;
    align-items: center;
    margin-top: 0.75rem;
}

.mediaContent > div {
    flex: 1 1 50%; /* Cada elemento ocupará el 50% del ancho */
}

.mediaPreview {
    margin-bottom: 1rem;
    max-width: 100%;
}

.videoWrapper {
    position: relative;
    /* 16:9 Aspect Ratio */
    max-width: 100%;
    overflow: hidden;

    
}

.responsiveIframe {
    position: relative;
    top: 0;
    left: 0;
    max-width: 100%;

}

.responsiveImage {
    max-width: 100%;
    height: auto;
    border-radius: 15px;
    border: 1px solid var(--border);
}



.grayHoverGreen p {
    color: #71767B;
}

.grayHoverGreen:hover p {
    color: var(--corporate);
}

.green p {
    color: var(--corporate);
}

.grayHoverBlue p {
    color: #71767B;
}

.grayHoverBlue:hover p {
    color: #0ea5e9;
}

.blue p {
    color: #0ea5e9;
}

.grayHoverRed p {
    color: #71767B;
}

.grayHoverRed:hover p {
    color: #F91880;
}

.red p {
    color: #F91880;
}


.iconCounterRepost {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.iconRepost {
    border-radius: 100%;
    padding-bottom: 7px;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 7px;
    transition: background-color 0.3s ease;
}

.iconRepost:hover {
    background-color: rgba(14, 165, 233, 0.2)
}


.iconCounterComment {
    display: flex;
    flex-direction: row;
    color: var(--corporate);
    align-items: center;
}

.iconComment {
    border-radius: 100%;
    padding-bottom: 7px;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 7px;
    transition: background-color 0.3s ease;
}

.iconComment:hover {
    background-color: rgba(9, 159, 93, 0.2);
}

.iconCounterLike {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.iconOutlinedLike {
    border-radius: 100%;
    padding-bottom: 7px;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 7px;
    transition: background-color 0.3s ease;
}

.iconOutlinedLike:hover {
    background-color: rgba(249, 24, 128, 0.2);
}


.iconCounterVisualizations {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.iconCounterVisualizations p {
    color: #71767B;
}

.iconCounterVisualizations:hover p {
    color: var(--corporate);
}

.iconVisualizations {
    border-radius: 100%;
    padding-bottom: 7px;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 7px;
    transition: background-color 0.3s ease;
}

.iconVisualizations:hover {
    background-color: rgba(9, 159, 93, 0.2);
}








.publicationCardContainer {

    cursor: pointer;
    transition: background-color 0.3s ease;

    border-bottom: 1px solid var(--border);


}

.borderInline {
    border-inline: 1px solid var(--border);
}


.publicationCardContent {
    display: flex;
    gap: 0.5rem;
    padding-top: 2%;
    padding-left: 3%;
    padding-right: 2%;
    padding-bottom: 1%;

}

.publicationCardContainer:hover {
    background-color: #0f0f0f;
}

.reposted {
    padding-left: 20px;
    padding-top: 10px;
    color: #71767b;
    z-index: 1001;
}

.repostText {
    font-size: 14px;
    ;
}

.repostText:hover {
    text-decoration: underline;
}

.publicationCardContainer .repostTopIcon {
    font-size: 20px !important;
    /* Añadir !important para sobrescribir cualquier otro estilo */
    margin-bottom: 2px;
    margin-right: 5px;
}



.publicationCardPlayerDetailsAndMoreHorizIcon {
    display: flex;
    justify-content: space-between;


}


.publicationCardPlayerDetails {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 0.5rem;
    margin-bottom: 0;
}


.publicationCardText {
    margin-top: 0;
    /* Eliminar margen superior */
    padding-top: 0;
    /* Eliminar padding superior */
    margin-bottom: 0;
    padding: 0;
}


.moreHorizIconContainer {
    padding-right: 6px;
}

.moreHorizIcon {
    color: #71767b;
    font-size: 23px;
}

.inactiveReactionButton {
    color: #71767b;
    gap: 0.75rem;
    display: flex;
    align-items: center;
}

.inactiveReactionButton:hover {
    color: var(--corporate);
    gap: 0.75rem;
    display: flex;
    align-items: center;
}

.inactiveButton {
    display: flex;
    align-items: center;
}

.inactiveButton p {
    color: #71767b;
}

.inactiveButton:hover p {
    color: var(--corporate);
}

.inactiveVisualizationsIcon {
    height: 14px;
}

.inactive14HeightIcon {
    height: 14px;
}

.inactive15HeightIcon{    
    height: 15px;
}

.activeRepostButton {
    
    gap: 0px;
    display: flex;
    align-items: center;
    color: var(--corporate);
}



.activeButton:hover {
    color: #F91880;
    gap: 0.75rem;
    display: flex;
    align-items: center;
}


.inactiveRepostButton {
    color: #71767b;
    gap: 0.75rem;
    display: flex;
    align-items: center;
}

.inactiveRepostButton:hover {
    color: #0ea5e9;
    gap: 0.75rem;
    display: flex;
    align-items: center;
}



.publicationCardLikeIconContainer {
    border-radius: 100%;
    padding-bottom: 7px;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 4px;
    transition: background-color;
}

.publicationCardLikeIconContainer:hover {
    background-color: rgba(219, 39, 119, 0.2);
    /* Color con transparencia (50% opaco) */
}

.publicationCardRepostIconContainer {
    border-radius: 100%;
    padding-bottom: 7px;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 4px;
    transition: background-color;
}

.publicationCardRepostIconContainer:hover {
    background-color: rgba(14, 165, 233, 0.2);
}


.publicationCardButtonsContainer .publicationCardIcon {
    height: 26px;
    margin-right: -13px;
}

.publicationCardIconContainer {
    border-radius: 100%;
    padding-bottom: 7px;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 4px;
    transition: background-color 0.3s ease;
}



.publicationCardIconContainer:hover {
    background-color: rgba(9, 159, 93, 0.2);
    /* Color con transparencia (50% opaco) */
}

.publicationCardImage {
    width: 60rem;
    border-radius: 0.75rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    border: 1px solid var(--border);
}

.publicationCardText {
    margin-bottom: 0;
    padding: 0;
}

.publicationCardButtonsContainer {
    padding-top: 4px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-left: -7px;

    font-size: 14px;

    padding-right: 10px;
}

.flex {
    display: flex;
}

.spaceX5 {
    gap: 1.25rem;
    /* space-x-5 */
}

.marginTop2Percent {
    margin-top: 2%;
}

.cursorPointer {
    cursor: pointer;
}

.widthFull {
    width: 100%;
}

.justifyBetween {
    justify-content: space-between;
}

.itemsCenter {
    align-items: center;
}

.cursorPointer {
    cursor: pointer;
}

.spaceX2 {
    gap: 0.5rem;
    /* space-x-2 */
}

.textGray500 {
    color: #71767b;
    /* text-gray-500 */
}

.mt0 {
    margin-top: 0;
}

.mb0 {
    margin-bottom: 0;
}

.padding0 {
    padding: 0;
}

.w60rem {
    width: 60rem;
}

.borderGray400 {
    border: 1px solid #9ca3af;
    /* border-gray-400 */
}

.roundedXl {
    border-radius: 0.75rem;
    /* rounded-xl */
}

.mt4 {
    margin-top: 1rem;
}

.mb2 {
    margin-bottom: 0.5rem;
}

.py3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.flexWrap {
    flex-wrap: wrap;
}

.textSky500 {
    color: #0ea5e9;
    /* text-sky-500 */
}

.textPink600 {
    color: #db2777;
    /* text-pink-600 */
}

.spaceX3 {
    gap: 0.75rem;
    /* space-x-3 */
}

.premiumBadge {
    width: 16px;
}

