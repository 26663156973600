.imageInput {
    display: none;
}

.modalHeader {
    position: sticky;
    top: 0;
    z-index: 20; /* Increased z-index */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-inline: 18px;
    padding-bottom: 10px;
    min-height: 32px;
    background-color: rgba(0, 0, 0, 0.6);
    /* Negro con 40% de opacidad */
    backdrop-filter: blur(8px);
    padding-top: 11px;
}

.modalHeader > div {
    display: flex;
    flex-direction: row;
    gap: 30px;
    font-weight: 700;
    font-size: 20px;
    align-items: center;
    margin-top: -2px;
}

.modalHeader > button {
    width: 73.5px;
    height: 32px;
    background-color: #eff3f4;
    color: black;
    font-weight: 500;
    font-size: 15px;
    border-radius: 30px;
}

.modalHeader > button:hover {
    background-color: #d8dcdd;
}

.modalHeader img {
    height: 13px;
}


.modalContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.uploader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 600px;
    max-height: 650px;
    background-color: black;
    border: none;
    outline: none;
    box-shadow: 24px 24px 48px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    overflow-y: auto; /* Enable vertical scrolling */
}

.headerTitle {
    font-size: 19px;
}

.backgroundImageContainer {
    width: 100%;
    height: 196px;
    position: relative;
}

.backgroundImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
}

.backgroundImageInput {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.profileImageContainer {
    transform: translateY(-42px);
    margin-left: 1rem;
    height: 127px;
    position: relative;
    display: inline-flex;
}

.profileImage {
    width: 120px !important;
    /* Añade !important para asegurar que sobrescriba los estilos de MUI */
    height: 120px !important;
    border: 3px solid black;
    border-radius: 100px;
    cursor: pointer;
}

.profileImageInput {
    position: absolute;
    top: 0;
    left: 0;
    width: 10rem;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.formContainer {
    padding: 0 17px;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    transform: translateY(-30px);
}


.saveButton {
    color: var(--corporate);
    font-weight: bold;
    padding-inline: 13px;
    padding-block: 5px;
    transition: background-color 0.3s ease;
}

.saveButton:hover {
    background-color: #0c2e1f;
}

.cancelButton {
    color: #b62525;
    font-weight: bold;
    padding-inline: 13px;
    padding-block: 5px;
    transition: background-color 0.3s ease;
}

.cancelButton:hover {
    background-color: #421919;
}

.saveAndCloseButton {
    display: flex;
    gap: 10px;
}


/* Para asegurar que el input range sea usable en móviles */
.zoomRange {
    -webkit-appearance: none;
    width: 80%;
    max-width: 300px;
    height: 4px;
    border-radius: 2px;
    background: #6de6b1;
    outline: none;
}

.zoomRange::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background: var(--corporate);
    cursor: pointer;
}

.zoomRange::-moz-range-thumb {
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background: #eff3f4;
    cursor: pointer;
    border: none;
}

.goBack {
    padding: 10px;
    border-radius: 30px;
    margin-left: -8px;
}

.goBack:hover {
    background-color: #141414;
}



@media screen and (max-width: 700px) {
    .uploader {
        max-width: 100%;
        height: 100vh;
        max-height: 100vh;
        margin: 0;
        border-radius: 0;
        position: fixed;
        top: 0;
        left: 0;
        transform: none;
    }

    .modalHeader {
        padding-inline: 16px;
    }

    .modalHeader > div {
        gap: 20px;
        font-size: 18px;
    }
}

/* Para pantallas muy bajas */
@media screen and (max-height: 600px) {
    .modalHeader {
        padding-bottom: 5px;
    }

    .controls {
        height: 40px;
        padding: 5px 0;
    }
}