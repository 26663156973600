/* circularprogress.module.css */
.circularProgress {
    width: 25px;
    height: 25px;
    margin-left: 1rem;
  }
  
  .circleBg {
    fill: none;
    stroke: #2e3235;
    stroke-width: 2.5;
  }
  
  .circleGreen {
    fill: none;
    stroke: var(--corporate); /* Color verde del botón */
    stroke-width: 2.8;
    stroke-linecap: round;
    transition: stroke-dasharray 0.3s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }

  .circleGray {
    fill: none;
    stroke: #2e3235; /* Color verde del botón */
    stroke-width: 2.8;
    stroke-linecap: round;
    transition: stroke-dasharray 0.3s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }

  .circleOrange {
    fill: none;
    stroke: #ffd400; /* Color verde del botón */
    stroke-width: 2.8;
    stroke-linecap: round;
    transition: stroke-dasharray 0.3s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }

  .circleRed {
    fill: none;
    stroke: #f4212e; /* Color verde del botón */
    stroke-width: 2.8;
    stroke-linecap: round;
    transition: stroke-dasharray 0.3s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }
  