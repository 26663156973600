.socialContainer {
  width: 100%;
  display: flex;
  gap: 30px;
  justify-content: center;
  background-color: black;
  min-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.feedContainer {
  margin-left: -26px;
  width: 600px;
  border-top: none;
  display: flex;
  flex-direction: column;
  background-color: black;
  border: 1px solid var(--border);
  padding: 20px;
}

.infoSection {
  border: 1px solid var(--border);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
}

.infoRow {
  display: flex;
  align-items: center;
}

.label {
  color: #808080;
  width: 128px;
}

.organizationInfo {
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px;
}

.organizationInfo:hover {
  background-color: #1e1e1e;
}

.orgLogo {
  width: 48px;
  height: 48px;
  border-radius: 5px;
}

.orgDetails {
  display: flex;
  flex-direction: column;
}

.orgName {
  font-weight: 600;
}

.orgUsername {
  color: #808080;
}

.cofoundersList {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-top: 15px;
}

.cofounderItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
}

.cofounderItem:hover {
  background-color: #1e1e1e;
}

.cofounderInfo {
  display: flex;
  align-items: center;
  gap: 16px;
}

.cofounderAvatar {
  width: 42px;
  height: 42px;
  border-radius: 50%;
}

.cofounderDetails {
  display: flex;
  flex-direction: column;
}

.statusAccepted {
  color: #22c55e;
}

.statusPending {
  color: var(--orange);
}

.statusDeclined {
    color: var(--red);
  }

.fixedHeader {
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 1000;
    top: 0;
    width: 598px;
    height: 53px;
    background-color: rgba(0, 0, 0, 0.6);
    /* Negro con 40% de opacidad */
    backdrop-filter: blur(8px);
    /* Aplica un desenfoque de 8px al fondo */
    padding-left: 15px;
    padding-block: 13px;
    margin-left: -20px;
}

.headerTitle {
    font-size: 1.28rem;
    font-weight: bold;
    opacity: 0.9;
    user-select: none;
    margin-left: 1.5rem;
    margin-top: 5px;
}


.headerTitle span {
    font-size: 14px;
    font-weight: 500;
    color: var(--secondary);
}

.arrowIcon {
    cursor: pointer;
}

.submitButton {
    background-color: var(--corporate);
    padding-block: 5px;
    margin-top: 5px;
    border-radius: 5px;
    font-weight: bold;
    width: 100%;
}

.submitButton:hover {
    background-color: #088650;
}

.disabledButton {
    opacity: 0.5;
    pointer-events: none;
}

@media (max-width: 1279px) {
    .feedContainer {
        margin-left: 0px; /* Elimina el margen izquierdo para evitar el solapamiento con el menú */

        width: 600px; /* Reduce el ancho del feed si es necesario */
    }
}

@media(max-width : 1100px){
    .socialContainer {
        gap: 20px;
    }
}

@media (max-width: 1000px) {
    .socialRightPart {
        display: none; /* Oculta la parte derecha en pantallas pequeñas */
    }
}

@media (max-width: 600px) {
    .feedContainer {
        width: 100%; /* Ocupa el 100% del espacio disponible */
        margin-left: 0; /* Asegura que el feed esté alineado con el borde izquierdo */
    }
}

@media (max-width: 499px) {
  .feedContainer {
      padding-bottom: 70px;
  }
}