/* worldviewpage.module.css */
.container {
  display: flex;
  position: fixed;
  width: 100%;
}

.mapContainer {
  background-color: var(--secondary-background);
 
  display: flex;
  width: calc(100vw - 270px);
  height: 100vh;
  cursor: pointer; /* Ajusta este cursor según tu lógica */

}

.sidebar {
  background: black;
  flex-grow: 1; /* Ocupa todo el espacio restante */
  display: flex;
  flex-direction: row;
  height: 90px;
  justify-content: space-between;
  align-items: center;
  padding: 11px 30px;
}

.rightContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #151616;
  padding: 10px;
  border-radius: 10px;
}

.iconContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5%;
}

.iconContainer button {
    margin: 5px;
}

.iconButton {
  color: white;
}

.shield {
  height: 65px;
  margin-right: 10px;
}

.regionContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start; /* Alinea las provincias a la izquierda */
}

.region {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 5px; /* Añade un margen para espaciar las provincias */
  height: 80px;
  padding-inline: 10px;
  cursor: pointer;
  border-radius: 6px;
}

.region:hover {
  background-color: #151616;
}

.flag {
  height: 55px;
  margin-right: 10px;
}

.regionDetails {
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Centra los elementos horizontalmente */
}

.regionTitle {
  margin: 0; /* Elimina márgenes superiores e inferiores */
  font-size: 17px;
}

.regionSubtitle {
  margin: 0; /* Elimina márgenes superiores e inferiores */
  line-height: 14px;
}

@media (max-width: 1380px) { /* Ajusta este valor según tus necesidades */
  .rightContainer {
    position: fixed;
    bottom: 15px;
    right: 15px;
    z-index: 1000; /* Asegura que esté por encima de otros elementos */
    background-color: black;
  }
}

@media (max-width: 1279px) {
  .mapContainer {
    width: calc(100vw - 90px);
  }

}

@media (max-width: 1230px) {

  .container {
    margin-left: 0px;
  }
}

@media (max-width: 940px) {
  .mapContainer {
    width: 100vw;
  }

  .currentRegion {
    display: none;
  }

}

@media (max-width: 740px) {

  .regionTitle {
    font-size: 15px;
  }

  
  
  .region {
    padding-inline: 5px; /* Reduce el padding inline de cada región */
    align-items: center;
    justify-content: center;
    width: 50%; /* Cada región ocupa exactamente el 50% */
    min-width: 0; /* Permite que el contenido se ajuste */
  }

}

@media (max-width: 610px) {
  .regionSubtitle {
    display: none;
  }

  .sidebar {
    padding: 2.5px 2.5px; /* Reduce el padding horizontal */
    width: calc(100vw - 92px);

  }
}

@media (max-width: 499px) {

  .rightContainer {
    display: none;
  }

  .sidebar {
    width: 100vw;

  }

}

