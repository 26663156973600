.buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}

.historyButton {
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border: 1px solid #555555;
}

.sendButton {
  background-color: var(--corporate);
  width: 100%;
  padding-block: 5px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  margin-top: auto;
}

.pageWrapper {
    height: 100vh;
    overflow: hidden;
    background-color: black;
  }
  
  .pageContainer {
    height: 100vh;
    overflow-y: auto;
    background-color: black;
    border-left: 1px solid var(--border);
  }
  
  .sidebar {
    position: sticky;
    top: 0;
    z-index: 1000;
    width: 100%;
    border-bottom: 1px solid var(--border);
    background-color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 11px 30px;
    gap: 5px;
    color: white;
  }
  
  .walletsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    padding: 30px 100px;
  }
  
  .wallet {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 10px;
    border-radius: 10px;
    background-color: var(--border);
    width: 200px;
    height: auto;
    cursor: pointer;
    word-break: break-all;
    text-align: center;
  }
  
  .wallet:hover {
    background-color: #1f1f20;
  }
  
  .walletHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }
  
  .walletHeader img {
    border-radius: 30px;
  }
  
  .walletContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }
  
  .balance {
    font-size: 20px;
    font-weight: 700;
  }


  @media (max-width: 925px) {
    .walletsContainer {
      padding-inline: 50px;
    }
  }

  @media (max-width: 400px) {
    .walletsContainer {
      padding-inline: 20px;
    }
  }