.loadingContainer {
    height: 99vh;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.loadingContainer img {
    height: 80px;
    margin-bottom: 60px;
}

.mainContent {

    width: 100%;
}

.headerTitle {
    font-size: 1.28rem;
    font-weight: bold;
    opacity: 0.9;
    user-select: none;
    margin-left: 1.1rem;
    margin-top: 5px;
}

.arrowIcon {
    margin-top: 1px;
    cursor: pointer;
}

.rightSide {
    position: fixed;
    right: 0;
    margin-right: 1.5%;
    width: 20%;
}




.profileElement {
    display: grid;
    grid-template-columns: 1fr 3fr; /* 1 parte para el primer p, 3 partes para el contenido */
    align-items: center; /* Centra verticalmente los elementos */
    gap: 10px; /* Espacio entre los elementos del grid */
}

.profileElementContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}



.tab {
    padding-top: 15px;
    padding-inline: 25px;
    color: var(--secondary);
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 75px;
    /* Ajusta el valor según la cantidad de separación deseada */
}

.cityPreview {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    padding-left: 10px;
    padding-block: 8px;
    border-radius: 4px;
}

.cityPreview p {
    color: white;
    font-weight: bold;
}

.cityPreview img {
    height: 40px;
}

.cityPreview:hover {
    background-color: #151616;
    cursor: pointer;
}

/* Clase adicional para elementos de ancho completo */
.fullWidthElement {
    grid-column: 1 / -1; /* Span across all columns */
    display: flex;
    justify-content: center;
    align-items: center;
}


.profileHeader {

    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    padding-right: 14px;


    top: -73px;
    /* Mueve la imagen de perfil hacia arriba la mitad de su altura */

    margin-left: 25px;

}

.rightContainer {
    display: flex;
    align-items: center;
    margin-left: auto;
    /* Empuja este div al extremo derecho */
    padding-right: 15px;
    /* Opcional: para agregar espacio en el extremo derecho */
}

.rightContainer p {
    font-weight: bold;
    margin-left: 2px;
}


.profileImage {
    /* Ajusta el tamaño según tus necesidades */
    height: 140px;
    /* Ajusta el tamaño según tus necesidades */
    border-radius: 8%;
    /* Hace que la imagen sea circular */

    /* Ajusta la imagen dentro del círculo */
    border: 4px solid transparent;
    /* Opcional: borde blanco alrededor de la imagen */
}


.profileInfo {
    padding-left: 1rem;
    margin-top: -73px;
    /* Ajusta la sección de información del perfil para que suba junto con la imagen de perfil */
}

.backgroundImage {
    width: 100%;
    height: 200px;
    object-fit: cover;
    margin-top: 52px;
}

.fixedHeader {
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 1000;
    top: 0;
    width: 598px;
    height: 53px;
    background-color: rgba(0, 0, 0, 0.6);
    /* Negro con 40% de opacidad */
    backdrop-filter: blur(8px);
    /* Aplica un desenfoque de 8px al fondo */
    padding-left: 15px;
    padding-block: 13px;
}



.userDetails {
    display: flex;
    flex-direction: column;
    margin-top: 13px;
    padding-right: 15px;
}

.userInfo {
    display: flex;
    align-items: center;
}

.name {
    font-size: 1.28rem;
    font-weight: bold;
    padding: 0;
}


.userStats {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}

.userStat {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.userStat p {
    color: var(--secondary);
}

.userStatsIcons {
    font-size: 20px;
    color: var(--secondary);
}


.grayText {
    color: var(--secondary);
}

.tabContainer {
    width: 100%;
    padding-top: 1.2rem;
}

.stateBadge {
    width: 16px;
    margin-left: 6px;
}

.territorialOrganizationButton {
    border: 1px solid #536471;
    padding-block: 10px;
    padding-inline: 30px;
    width: 100%;
    border-radius: 100px;

    margin-bottom: 30px;
}

.territorialOrganizationButton:hover {
    background-color: #151616;
    font-weight: bold;
}

.travelButton {
    padding: 8px;
    border-radius: 100px;
}

.travelButton:hover {
    background-color: #151616;
    color: white;
}

.imageContainer {
    width: 50px;
    display: flex;
    justify-content: center;
}

.levelAndExperienceBar {
    display: flex;
    flex-direction: row;
    gap: 5%;
}

.experienceBarContainer {
    width: 100%;
    background-color: #e0e0e0;
    /* Color de fondo de la barra de experiencia */
    border-radius: 10px;
    /* Bordes redondeados para la barra de experiencia */
    height: 10px;
    /* Altura de la barra de experiencia */
    margin-top: 8px;
    /* Espacio entre el texto y la barra de experiencia */
}

.experienceBar {
    height: 100%;
    background-color: var(--corporate);
    /* Color de la barra de experiencia */
    border-radius: 10px;
    /* Bordes redondeados para la barra de experiencia */
}

.elementTitle {
    display: flex;
    flex-direction: column;
    gap: 6px;
    color: white;
    font-weight: bold;
    font-size: 18px;
}

.elementTitle hr {
    border: 0; /* Elimina el borde predeterminado del <hr> */
    border-top: 2px solid var(--secondary); /* Define el color y el grosor del borde superior */
    margin: 0; /* Opcional: elimina el margen predeterminado */
}

.logoutButton {
    border-radius: 100px;
    border: 1px solid #536471;
    height: 35px;
    padding: 0 15px; /* Ajusta el padding horizontal */
    margin-top: 72px;
    display: flex; /* Usa flexbox para alinear el contenido */
    align-items: center; /* Centra verticalmente el contenido */
    justify-content: center; /* Centra horizontalmente el contenido */
    transition: background-color 0.3s ease;

    gap: 0.5rem;
}

.statisticsContainer {
    padding-top: 10px;
    padding-bottom: 20px;

    overflow-y: auto; /* Añade scroll si el contenido desborda verticalmente */
    overflow-x: hidden; /* Oculta cualquier desbordamiento horizontal */
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Dos elementos por fila */
    justify-items: center; /* Centra los elementos horizontalmente */
    align-items: stretch; /* Ensures equal heights for divs in a row */

    gap: 10px;

    width: 100%;
}

.pieChartWrapper {
    width: 100%;
    display: flex;
}

.pieChartWrapper:nth-child(odd) {
    justify-content: flex-end;
}

.pieChartWrapper:nth-child(even) {
    justify-content: flex-start;
}

.quarterDropdown {
    padding-left: 15px;
}

.popsDistributions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.statisticsQuarterAndYear {
    padding-top: 15px;
    padding-inline: 25px;
    padding-bottom: 10px;
}


@media (max-width : 690px) {
    .statisticsContainer {
        grid-template-columns: repeat(1, 1fr); /* Dos elementos por fila */
    }

    .pieChartWrapper:nth-child(odd) {
        justify-content: center;
    }
    
    .pieChartWrapper:nth-child(even) {
        justify-content: center;
    }
}

@media (max-width : 499px) {
    .statisticsContainer {
        padding-bottom: 70px;
    }
}