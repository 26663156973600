.foundingItem {
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    cursor: pointer;
}

.foundingItem:hover {
    background-color: var(--elegant-hover);
}

.foundingItem div {
    display: flex;
    
    gap: 5px;
}

.foundingItem img {
    width: 40px;
    height: 40px;
    border-radius: 5px;
}

.organizationType {
    font-size: 18px;
    font-weight: 500;
    text-decoration: underline;
    line-height: 1;
}

.foundingItemInfo {
    display: flex;
    flex-direction: column;
    line-height: 1;
}

.foundingItemName {
    font-weight: bold;
    font-size: 17px;
}

.foundingItemUsername {
    color: var(--secondary);
}

.pageContainer {
    height: 100vh;
    overflow: hidden;
    background-color: #1b1b1b;
}

.mainContent {
    height: 100%;
    overflow-y: auto;
    background-color: black;
    border-left: 1px solid var(--border);
}

.sidebar {
    position: sticky;
    top: 0;
    z-index: 1000;
    border-bottom: 1px solid var(--border);
    background-color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 11px 30px;
    gap: 5px;
    color: white;
    width: 100%;
}

.organizationsContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 20px 5%;
    justify-items: center;
    align-items: stretch;
}

.myOrganizations {
    border: 2px solid var(--border);
    background-color: #0a0a0a;
    padding: 15px;
    border-radius: 5px;
    width: 100%;
    max-width: 440px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin: 0;
    /* Remove justify-content: space-between to prevent pushing content apart */
    /* Add min-height to ensure cards stay the same size */
    min-height: 250px;
}


.myOrganizationsTitle {
    font-weight: bold;
    font-size: 25px;
}

.myOrganizationsButton {
    margin-top: 5px;
    padding-block: 5px;
    border: 1px solid transparent;
    border-radius: 100px;
    width: 100%;
    background-color: #088650;
    font-weight: bold;

    cursor: pointer;
}

.myOrganizationsButton:hover {
    background-color: var(--corporate);
    font-weight: bold;
}

.skillTitle {
    font-weight: bold;
    font-size: 25px;
}

.titleAndRequiredLevel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.contentWrapper {
    /* Remove flex-grow: 1 to prevent pushing content down */
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* Add margin-bottom for spacing */
    margin-bottom: 10px;
}

.contentWrapper p {
    color: var(--secondary);
}

.contentWrapper span {
    color: var(--corporate);
}

.buttons {
    display: flex;
    flex-direction: row;
    gap: 8px;

    margin-top: auto;
}

.actualLevel {
    margin-top: 5px;
    padding-block: 5px;
    padding-inline: 10px;
    border: 1px solid var(--secondary);
    border-radius: 100px;
}

.learningDiv {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.learningDiv img {
    width: 41px;
}

.learning {
    margin-top: 5px;
    padding-block: 5px;
    border: 1px solid var(--corporate);
    border-radius: 100px;
    width: 100%;
    color: var(--corporate);
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.learningRing {
    display: flex;
}

.loading {
    display: flex;
    height: 100vh;
    /* Ocupa toda la altura de la pantalla */
    align-items: center;
    justify-content: center;
    background-color: #1b1b1b;
}



@media (max-width: 1200px) {
    .organizationsContainer {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 800px) {
    .organizationsContainer {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (max-width: 500px) {
    .market, .myOrganizations {
        width: 100%;
        max-width: 100%;
    }
}