.upperSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.level {
    font-weight: 500;
    color: var(--secondary);
}



.nameAndBadge {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
}

.container {
    padding-top: 51px;
}

.hotIcon {
    margin-right: 5px;
    /* Espacio entre el icono y el texto */
}

.backgroundImage {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.tabContainer {
    width: 100.1%;
    padding-top: 5px;
    display: flex;
    justify-content: center;
}




.cofoundersList {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.cofounderItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-content: space-between;
    cursor: pointer;
    padding-inline: 15px;
    padding-block: 10px;

    min-width: 0; /* Permitir que el contenedor se encoja */
}

.cofounderItem:hover {
    background-color: var(--elegant-hover);
}

.cofounderItem:hover .level {
    color: var(--primary);
}

.cofounderInfo {
    display: flex;
    align-items: center;
    gap: 10px;
}

.cofounderAvatar {
    width: 45px;
    height: 45px;
    border-radius: 50%;
}

.cofounderDetails {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.cofounderDetails p {
    line-height: 20px;

}

.name {
    font-weight: 600;
}

.username {
    color: #808080;
}

.description {
    margin-top: 4px;
    /* Añadimos un pequeño espacio arriba de la descripción */
    color: #E7E9EA;
    /* Color típico de Twitter para el texto */
    line-height: 1.3;
    /* Mejoramos la legibilidad */
}

.aboutContainer {
    padding-left: 55px;
}