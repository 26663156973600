.legalStatus {
    color: var(--corporate);
}

.illegalStatus {
    color: var(--red);
}

.undefinedStatus {
    color: var(--secondary);
}

.legalityIcon {
    font-size: 18px;
}

.userStat {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
