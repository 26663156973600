.modalContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    background-color: #000000;
    border: none;
    box-shadow: 24px;
    padding: 16px;
    outline: none;
    border-radius: 5px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2%;
}

.headerLeft {
    display: flex;
    align-items: center;
    gap: 12px;
}

.headerText {
    color: white;
}

.headerText strong {
    font-size: 1.125rem;
    /* text-lg in Tailwind CSS */
}

.travelDataContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.cities {
    display: flex;
    flex-direction: row; 
    justify-content: space-between;
    width: 100%;

    align-items: center;
}

.cityAndCountry {
    display: flex;
    flex-direction: row;
    gap: 15px
}

.cityAndCountry img {
    height: 80px;
    width: auto;
    object-fit: contain;
  }

  .cityOrCountry {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: var(--secondary);
  }

  .arrowIcon {
    font-size: 60px;
  }

  .travelDataContainer button {
    border-radius: 30px;
    padding-block: 5px;
    font-weight: bold;
    color: white;
    background-color: #0bac66;
  }

  .infoItem {
    display: grid;
    grid-template-columns: 150px 1fr; /* Crea dos columnas con ancho automático */
    gap: 10px; /* Espacio entre las columnas */
  }

  .infoItem .title {
    color: var(--secondary);
  }

  .greenText {
    color: #0bac66;
  }

  .redText {
    color: red;
  }

  .levelAndExperienceBar {
    display: flex;
    flex-direction: row;
    gap: 5%;
}

.experienceBarContainer {
    width: 100%;
    background-color: #e0e0e0;
    /* Color de fondo de la barra de experiencia */
    border-radius: 10px;
    /* Bordes redondeados para la barra de experiencia */
    height: 10px;
    /* Altura de la barra de experiencia */
    margin-top: 8px;
    /* Espacio entre el texto y la barra de experiencia */
}

.experienceBar {
    height: 100%;
    background-color: var(--corporate);
    /* Color de la barra de experiencia */
    border-radius: 10px;
}

.loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 220px;
}

.loadingContainer img {
    height: 80px;
}

@media (max-width : 650px){

    .modalContainer {
        width: 90%;
    }

}

@media (max-width : 550px){

    .cities {
        flex-direction: column; 
    }

}