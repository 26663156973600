.fixedHeader {
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 1000;
    top: 0;
    width: 598px;
    height: 53px;
    background-color: rgba(0, 0, 0, 0.6);
    /* Negro con 40% de opacidad */
    backdrop-filter: blur(8px);
    /* Aplica un desenfoque de 8px al fondo */
    padding-left: 16px;
    padding-block: 13px;
}

.fixedHeader > button {
   width: 35px;
   height: 35px;
   padding: 0;
   display: flex;
   align-items: center;
   justify-content: center;
}

.goBack {
   padding: 12.5px;
   border-radius: 50%;
   margin-left: -7px;
}

.fixedHeader > button:hover {
    background-color: var(--hover);
}

.fixedHeader img {
    height: 12.5px;
}

.titlesContainer {
    display: flex;
    flex-direction: column;
    padding-left: 28px;
}

.titlesContainer h1 {
    padding: 0;
    margin-top: -2px;
    
}

.headerTitle {
    font-size: 20px;
}

.headerSubtitle {
    color: var(--secondary);
    font-size: 13px;
    margin-top: -2px;
}



.goBack:hover {
    background-color: #141414;
}

.rightContainer {
    display: flex;
    align-items: center;
    margin-left: auto;
    /* Empuja este div al extremo derecho */
    padding-right: 15px;
    /* Opcional: para agregar espacio en el extremo derecho */
}

.rightContainer p {
    font-weight: bold;
    margin-left: 2px;
}

.headerTitle span {
    font-size: 14px;
    font-weight: 500;
    color: var(--secondary);
}


@media (max-width: 692px) {
    .fixedHeader {
        width: calc(100% - 90px);
    }
}

@media (max-width: 499px) {
    .fixedHeader {
        width: 100%;
    }

    .titlesContainer h1 {
        font-size: 18.5px;
    }
}