.checkIcon {
    color: var(--corporate);
}

.submitButtonContainer {
    display: flex;
    flex-direction: column;
}

.searchBar {
    margin-top: 10px;
    margin-bottom: 20px;
}


.submitButton {
    background-color: var(--corporate);
    padding-block: 5px;
    margin-top: 20px;
    border-radius: 5px;
    font-weight: bold;
}

.submitButton:hover {
    background-color: #088650;
}

.disabledButton {
    opacity: 0.5;
    pointer-events: none;
}

.headquartersSection {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.headquartersSection img {
    width: 80px;
    height: 80px;
    object-fit: contain;
}

.headquartersSection>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}


.coFoundersSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.avatarContainer {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
}

.avatarContainer p:first-of-type {
    font-weight: bold;
    font-size: 18px;
}

.avatarContainer p:nth-of-type(2) {
    color: #71767b;
    font-size: 14px;
}

.cofounderImage {
    width: 45px !important;
    height: 45px !important;
}

.overlayButton {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: none;
    display: flex;
    cursor: pointer;
}




.titleSection {
    display: flex;
    flex-direction: column;
    gap: 6px;


    padding-bottom: 5px;
}

.title {
    font-weight: bold;
    font-size: 18px;
    color: white;
}

.infoText {
    color: #71767b;
    padding-top: 5px;
}

hr {
    border: 0;
    /* Elimina el borde predeterminado del <hr> */
    border-top: 2px solid var(--secondary);
    /* Define el color y el grosor del borde superior */
    margin: 0;
    /* Opcional: elimina el margen predeterminado */
}

.textAndInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.noImagesForm {
    display: flex;
    flex-direction: column;
    padding-inline: 17px;
    gap: 15px;

    margin-top: -50px;
}

.imageInput {
    display: none;
}



.mainContent {
    width: 100%;
}

.loadingContainer {
    height: 99vh;
    display: flex;
    justify-content: center;
    align-items: center;

}


.headerTitle {
    font-size: 1.28rem;
    font-weight: bold;
    opacity: 0.9;
    user-select: none;
    margin-left: 1rem;

    margin-top: 5px;
}

.arrowIcon {
    cursor: pointer;
    margin-top: 1px;
}

.form {
    height: auto;
    padding-bottom: 30px;
}




.profileImage {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -73px;
    left: 18px;
    cursor: pointer;

    width: 140px !important;
    /* Añade !important para asegurar que sobrescriba los estilos de MUI */
    height: 140px !important;
    /* Ajusta el tamaño según tus necesidades */
    border-radius: 10% !important;
    /* Hace que la imagen sea circular */
    object-fit: cover;
    /* Ajusta la imagen dentro del círculo */
    border: 4px solid black;
    /* Opcional: borde blanco alrededor de la imagen */
}



.fixedHeader {
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 1000;
    top: 0;
    width: 598px;
    height: 53px;
    background-color: rgba(0, 0, 0, 0.6);
    /* Negro con 40% de opacidad */
    backdrop-filter: blur(8px);
    /* Aplica un desenfoque de 8px al fondo */
    padding-left: 15px;
}





.backgroundImage {
    width: 100%;
    height: 200px;
    object-fit: cover;
    margin-top: 52px;
    cursor: pointer;
}





@media (max-width: 616px) {
    .fixedHeader {
        width: 100%;
    }
}

@media (max-width: 499px) {
    .headerTitle {
        font-size: 1.1rem;
    }

    .content {
        padding-bottom: 40px;
    }
}