.buttonsContainer {
  margin-top: 75px;
  display: flex;
  flex-direction: column;
}

.buttonsContainer button {
  text-align: left;
  padding-block: 15px;
  padding-inline: 17px;
  color: #E7E9EA;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.buttonsContainer button div {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.buttonsContainer button:hover {
  background-color: #242424;
  font-weight: 500;
}

.houseMenu {
  font-weight: bold;
  font-size: 20px;

  position: absolute;
  top: 2.75%;
  /* Centrado vertical */
  right: 190px;
  /* Ajusta la posición horizontal */
}

/* El botón del menú */
.menuToogle {
  width: 30px;
  height: 20px;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Las tres líneas del menú */
.line {
  display: block;
  width: 100%;
  height: 3px;
  background-color: white;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
}

/* El div que contiene el párrafo */
.show {
  display: block;
  position: fixed;
  /* Posición fija */
}

.menu {
  top: 0;
  /* Empieza desde la parte superior */
  right: 0;
  /* Posición en el lado derecho */
  bottom: 0;
  z-index: 1000;
  width: 325px;
  /* Ancho específico */
  height: 100vh;
  /* Altura completa de la pantalla */
  background-color: rgb(15, 15, 15);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  transform: translateX(100%);
  /* Empieza fuera de la vista a la derecha */
  transition: transform 0.3s ease-in-out;
  /* Suavizado para el desplazamiento */
  border-radius: 10px;
  border: 1px solid rgb(70, 70, 70);
  border-right: none;
}

/* Estado oculto */
.hidden {
  visibility: hidden;
  /* El menú no será visible pero permanece en el flujo */
  opacity: 0;
  /* Hace que el menú sea completamente transparente */
  transform: translateX(100%);
  /* Mantiene el menú fuera de la vista */
  transition: visibility 0s 0.3s, opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  /* Transición suave */
}

/* Estado visible */
.show.open {
  visibility: visible;
  /* El menú es visible */
  opacity: 1;
  /* El menú es opaco */
  transform: translateX(0);
  /* Mueve el menú dentro de la vista */
  transition: visibility 0s 0s, opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  /* Transición suave */
}

/* Animación opcional para las líneas cuando el menú está abierto */
.menuToogle.active .line:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.menuToogle.active .line:nth-child(2) {
  opacity: 0;
}

.menuToogle.active .line:nth-child(3) {
  transform: rotate(-45deg) translate(7px, -6px);
}

/* Estilos para el contenedor de la "X" (botón de cierre) */
.closeButton {
  position: absolute;
  top: 3%;
  /* Centrado vertical */
  right: 1.75rem;
  /* Ajusta la posición horizontal */

  display: flex;

  align-items: center;
  /* Centrado vertical de las líneas */
  width: 27px;
  /* Tamaño de la "X" */
  height: 27px;
  /* Tamaño de la "X" */
  cursor: pointer;
  z-index: 1100;
  /* Asegura que esté encima del menú */


  border-radius: 2px;
}

/* Las líneas que forman la "X" */
.lineX {
  width: 100%;
  height: 3px;
  background-color: white;
  border-radius: 3px;
  position: absolute;
  transition: all 0.3s ease-in-out;
}

/* Posiciona las dos líneas de la "X" en forma de cruce */
.lineX:nth-child(1) {
  transform: rotate(45deg);
  /* Rota la primera línea */
}

.lineX:nth-child(2) {
  transform: rotate(-45deg);
  /* Rota la segunda línea */
}



.container {
  display: flex;
  position: fixed;
  width: 100%;
}

.mapContainer {
  flex-grow: 1;
  /* Ocupa todo el espacio restante */
  display: flex;
  width: calc(100vw - 270px);
  height: calc(100vh - 90px);
  background-color: var(--secondary-background);
  cursor: pointer;
  /* Ajusta este cursor según tu lógica */
}


.sidebar {
  background: black;
  flex-grow: 1;
  /* Ocupa todo el espacio restante */
  display: flex;
  flex-direction: row;
  height: 90px;
  justify-content: space-between;
  align-items: center;
  padding: 11px 30px;
}

.mapModeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.iconContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5%;
}

.iconContainer button {
  margin: 5px;
}

.iconButton {
  color: white;
}

.shield {
  height: 65px;
  margin-right: 10px;
  border-radius: 5px;
}

.legislator {
  height: 65px;
  margin-right: 10px;
  border-radius: 30px;
}

.regionContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  /* Alinea las provincias a la izquierda */
  gap: 5px;
}

.region {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 80px;
  padding-inline: 10px;
  cursor: pointer;
  border-radius: 6px;
}

.region:hover {
  background-color: #151616;
}

.flag {
  height: 55px;
  margin-right: 10px;
}

.regionDetails {
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* Centra los elementos horizontalmente */
}

.regionTitle {
  margin: 0;
  /* Elimina márgenes superiores e inferiores */
  font-size: 17px;
}

.regionSubtitle {
  margin: 0;
  /* Elimina márgenes superiores e inferiores */
  line-height: 14px;
}

@media (max-width: 1279px) {
  .mapContainer {
    width: calc(100vw - 90px);
  }

  .svgContainer {
    width: calc(100vw - 100px);
    cursor: pointer;
    /* Ajusta este cursor según tu lógica */
  }

}

@media (max-width: 1230px) {

  .container {
    margin-left: 0px;
  }
}

@media (max-width: 768px) {}

@media (max-width: 499px) {

  .menu {
    width: 100vw;
  }

  .houseMenu {
    /* Centrado vertical */
    left: 16px;
    /* Ajusta la posición horizontal */
  }

  .mapContainer {
    width: 100vw;
  }

}