.modalStyle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    background-color: #000000;
    border: none;
    box-shadow: 24px;
    padding: 16px;
    outline: none;
    border-radius: 4px;
}

.modalContent {
    display: flex;
    flex-direction: column;
    height: 260px;
    overflow-x: hidden;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2%;
}

.headerLeft {
    display: flex;
    align-items: center;
    gap: 12px;
}

.headerText {
    color: white;
}

.headerText strong {
    font-size: 1.125rem;
}

.skillContainer {
    display: flex;
    flex-direction: row;
    padding: 10px;
    padding-left: 0px;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
}

.skillInfo {
    display: flex;
    flex-direction: column;
    width: 85px;
}

.levelUpButton {
    border: 1px solid #536471;
    border-radius: 100px;
    padding-block: 8px;
    width: 100px;
    transition: background-color 0.3s ease;
}

.skillLevel {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.levelUpButton:hover {
    background-color: #151616;
}

@media (max-width:650px) {
    .modalStyle {
        width: 90%;
    }
}

@media (max-width:505px) {

    .skillContainer {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Dos elementos por fila */
        align-items: center;
        gap: 0.5rem;
        padding: 10px;
    }

    .levelUpButton {
        grid-column: span 2; /* Ocupa dos columnas */
        width: 100%;
        height: 40px;
    }

    .modalContent {
        height: 400px;
    }

}