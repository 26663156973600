.profileElement ul p {
    color: var(--secondary);
}

.profileElement > p {
    color: var(--secondary);
}

.profileElement span {
    color: var(--secondary);
}

.profileElement li::marker {
    color: var(--secondary);
}

.positionInfo {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
}

.positionTitle {
    font-weight: bold;
    font-size: 16px;
    text-decoration: underline;
}

ul li {
    color: #E7E9EA;
}

.mainContent {
    width: 100%;
}

.loadingContainer {
    height: 99vh;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.loadingContainer img {
    height: 80px;
    margin-bottom: 60px;
}

.headerTitle {
    font-size: 1.28rem;
    font-weight: bold;
    opacity: 0.9;
    user-select: none;
    margin-left: 1.1rem;
    margin-top: 5px;
}

.arrowIcon {
    cursor: pointer;
    margin-top: 1px;
}

.premiumBadge {
    width: 16px;
    margin-left: 6px;
}

.rightSide {
    position: fixed;
    right: 0;
    margin-right: 1.5%;
    width: 20%;
}

.tab {
    padding-top: 15px;
    padding-inline: 25px;
    color: var(--secondary);

    display: flex;
    flex-direction: column;
    gap: 15px;
    /* Ajusta el valor según la cantidad de separación deseada */
}

.positionListElement{
    display: flex;
    flex-direction: column;
    color: white;
    gap: 0.5rem;
    font-weight: 500;
    padding: 0.5rem;
    padding-left: 0.75rem;
    border-radius: 10px;
    width: auto;

}

.positionListElement:hover {
    background-color: var(--elegant-hover);

    cursor:pointer;

}

.positionListElement img {
    height: 40px;
}

.nationalitySection {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.nationalityListElement {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    gap: 1rem;
    font-weight: 500;
    padding: 0.5rem;
    padding-left: 0.75rem;
    border-radius: 10px;
    width: auto;

}

.nationalityListElement:hover {
    background-color: var(--elegant-hover);

    cursor:pointer;

}

.nationalityListElement img {
    height: 40px;
}

.nationalityInfo {
    display: flex;
    flex-direction: column;
}

.skillsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}


.skillForProfile {
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
    padding: 10px;
    /* Incrementa el padding según sea necesario */
    align-items: center;
}

.skillDetails {
    display: flex;
    flex-direction: column;
}

.attributeForProfile {
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
    padding: 10px;
    /* Incrementa el padding según sea necesario */
    align-items: center;
}

.attributeForProfile:hover {
    background-color: var(--elegant-hover);
    color: white;
}

.attributeDetails {
    display: flex;
    flex-direction: column;
}



.skills:hover {
    cursor: pointer;
    background-color: var(--elegant-hover);
    color: white;
}


.levelAndExperienceBar {
    display: flex;
    flex-direction: row;
    gap: 5%;
}

.experienceBarContainer {
    width: 100%;
    background-color: #e0e0e0;
    /* Color de fondo de la barra de experiencia */
    border-radius: 10px;
    /* Bordes redondeados para la barra de experiencia */
    height: 10px;
    /* Altura de la barra de experiencia */
    margin-top: 8px;
    /* Espacio entre el texto y la barra de experiencia */
}

.experienceBar {
    height: 100%;
    background-color: var(--corporate);
    /* Color de la barra de experiencia */
    border-radius: 10px;
    /* Bordes redondeados para la barra de experiencia */
}

.editFollowUnfollowButton {
    border-radius: 20px;
    height: 35px;
    padding: 5px 17px; /* Ajusta el padding horizontal para centrar el contenido */
    margin-top: 72px;
    display: flex; /* Usa flexbox para alinear el contenido */
    align-items: center; /* Centra verticalmente */
    justify-content: center; /* Centra horizontalmente */
    transition: background-color 0.3s ease;
    text-transform: none;
    font-size: 15px;
}

.borderButton {
    border: 1px solid var(--silver);
}

.editFollowUnfollowButton:hover {
    background-color: var(--elegant-hover);
}

.followButton {
    background-color: white;
    color: black;
}

.followButton:hover {
    background-color: #dddddd;
}

.unfollowButton {
    background-color: black;
    border: 1px solid var(--silver);
}

.unfollowButton:hover {
    background-color: #180305;
    border-color: #d91d29;
    color: #d91d29;
}


.profileHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    top: -73px;
    /* Mueve la imagen de perfil hacia arriba la mitad de su altura */
    padding-right: 14px;
    padding-left: 18px;
}

.rightContainer {
    display: flex;
    align-items: center;
    margin-left: auto;
    /* Empuja este div al extremo derecho */
    padding-right: 15px;
    /* Opcional: para agregar espacio en el extremo derecho */
}

.rightContainer p {
    font-weight: bold;
    margin-left: 2px;
}

.hotIcon {
    margin-right: 5px;
    /* Espacio entre el icono y el texto */
}

.profileHeader .profileImage {
    width: 140px;  /* Ajusta el tamaño según tus necesidades */
    height: 140px; /* Ajusta el tamaño según tus necesidades */
    border-radius: 50%;  /* Hace que la imagen sea circular */
    object-fit: cover;   /* Ajusta la imagen dentro del círculo */
    border: 4px solid black;  /* Opcional: borde blanco alrededor de la imagen */
}

.profileInfo {
    padding-left: 1rem;
    margin-top: -73px;
    /* Ajusta la sección de información del perfil para que suba junto con la imagen de perfil */
}

.backgroundImage {
    width: 100%;
    height: 200px;
    object-fit: cover;
    margin-top: 52px;
}

.avatar {
    transform: translateY(-50%);
    width: 10rem;
    height: 10rem;
    border: 4px solid white;
}

.userDetails {
    display: flex;
    flex-direction: column;
    margin-top: 13px;
}

.userInfo {
    display: flex;
    align-items: center;
    gap: 5px;
}

.name {

    font-size: 21px;
    font-weight: bold;

    padding: 0;
}

.username {
    font-size: 1rem;
    font-weight: normal;
    color: #71767B;

    line-height: 15px;

    padding: 0;

}

.workDiscipline {
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    border: 1px solid var(--border);
    color: white;
    border-radius: 100px;
    padding-block: 5px;
}

.workDiscipline:hover {
    background-color: var(--elegant-hover);
    font-weight: 600;
}

.userStats {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    color: var(--secondary);
    flex-wrap: wrap; /* Permitir que los elementos se envuelvan en varias filas */
}



.userStat {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.userStat p {
    color: var(--secondary);
}

.userStat span:hover {
    cursor: pointer;
    text-decoration: underline;
}

.userStatsIcons {
    font-size: 20px;
    color: var(--secondary);
}

.followingFollowersNumber {
    font-weight: bold;
    color: white;
}

.grayText {
    color: var(--secondary);
}

.tabContainer {
    width: 100%;
    padding-top: 15px;
    padding-bottom: 70px;
}

.personalAttributesSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.ideologiesSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

ul {
    list-style-type: disc; /* Esto asegura que las listas usen círculos */
    padding-left: 20px;    /* Ajusta según sea necesario para tu diseño */
}

li {
    margin-bottom: 0.5rem; /* Esto es opcional, para agregar espacio entre los elementos */
}

.profileWord {
    margin-left: 5px; /* Ajusta el espacio entre las palabras "Edit" y "profile" */
}

.logoutButton {
    border-radius: 100px;
    border: 1px solid var(--silver);
    height: 35px;
    padding: 0 15px; /* Ajusta el padding horizontal */
    margin-top: 72px;
    display: flex; /* Usa flexbox para alinear el contenido */
    align-items: center; /* Centra verticalmente el contenido */
    justify-content: center; /* Centra horizontalmente el contenido */
    transition: background-color 0.3s ease;
}


.logoutButton:hover {
    background-color: red;
    border-color: red;
}

.profileElement {
    display: grid;
    grid-template-columns: 1fr 3fr; /* 1 parte para el primer p, 3 partes para el contenido */
    align-items: center; /* Centra verticalmente los elementos */
    gap: 10px; /* Espacio entre los elementos del grid */
}

.skillsAttributesContainer {
    display: flex; 
    justify-content: space-between;
}



@media (max-width: 616px) {
    .profileElement {
        grid-template-columns: 1fr 4fr; /* 1 parte para el primer p, 3 partes para el contenido */
    }
}

@media (max-width: 548px) {
    .skillsAttributesContainer {
        flex-direction: column;
    }
    .profileElement {
        grid-template-columns: 1fr 2fr; /* 1 parte para el primer p, 3 partes para el contenido */
    }
}

@media (max-width: 380px) {
    .profileWord {
        display: none;
    }
}