

.container {
    height: 100%;
    color: black;
}

.container {
    background-color: #ffffff;
}


.leftPartHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.6rem;
}

.leftPartHeader p {
    font-weight: bold;
    font-size: 23px;
}

.content {
    background-color: black;
    height: 100%;
    padding-top: 95px;
}

.header {
    justify-content: space-between;
    padding-left: 50px;
    padding-right: 50px;

    padding-top: 30px;
    padding-bottom: 20px;

    width: 100%;

    background-color: #ffffff;


    position: fixed;

    display: flex;
    flex-direction: row;
}

.worldOrderLogo svg {
    transform: scale(1);
}

.rightPartHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.6rem;
}

.rightPartHeader button {

    border: 2px solid #e8eff3;
    padding-block: 10px;
    padding-inline: 20px;
    font-size: 14px;
    border-radius: 30px;

}


@media (max-width : 520px) {
    .rightPartHeader button {
        display: none;
    }

    .header {
        padding-bottom: 25px;
    }
}

@media (max-width : 1020px) {

    .header {
        padding-left: 25px;
    }

}